// this file will have some constant values for styles that are used in multiple components across the app.
// they can be changed once here.

// minimum screen width at which we start using desktop margins for SIDE margins
export const desktopMarginsCutoff = 540;

// actual px values of margins
// this is ONLY FOR LEFT AND RIGHT SIDE MARGINS.
export const mobileMarginVal = 16;
export const desktopMarginVal = 24;

// height of main navbar, NOT including 1 pixel border below.
export const navbarHeight = 62;

// z-index values
// the subnavbar needs to be at least 2 to cover the material ui input fields which for some reason (may have internal z-index), will show up above navbars with z-index less than 2
// subnavbar also applies to any page specific headers or footers that stick to page, but must be less than the main navbar
export const subNavBarZ = 2;
// the main navbar (the top navbar) must be greater than subnavbar to cover subnavbar when navigation or account menus are open.
// this z-index includes the top navbar but also the main nav menu and account menu that fully open.
export const mainNavBarZ = 3;

// this can be used for all information slide ins or overlays that need to trump even the main navbar.
// includes slide ins (information, sign in condtions), the confirmation overlay on petition vote, and disable screens that block navbar.
// the "background" value can be like the gray background that covers the screen but is behind the contents.
export const overlayBackgroundZ = 5;
// this is the the solid white overlay or slide in with the text that needs to be above the background.
export const overlayZ = 6;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  border-radius: 6px;
  border: 1px solid black;
  text-align: center;
  color: ${(props) => (props.$isActive ? "white" : "black")};
  background: ${(props) => (props.$isActive ? "black" : "white")};
  font-size: 14px;
  letter-spacing: 0.05em;
  font-weight: ${(props) => (props.$isActive ? "700" : "700")};

  height: 34px;
  line-height: 34px;
  width: 100%;
  max-width: 236px;

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: pointer;
  &:hover {
    background: ${(props) => (props.$isActive ? "white" : "black")};
    color: ${(props) => (props.$isActive ? "black" : "white")};
    border: 1px solid black;
  }
`;

const PetitionButton = ({ text, onClick, isActive }) => {
  return (
    <Wrapper onClick={() => onClick()} $isActive={isActive}>
      {text}
    </Wrapper>
  );
};

PetitionButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default PetitionButton;

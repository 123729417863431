import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import screenWidthReducer from "./screenWidthSlice";
import freezeAppScrollingReducer from "./freezeAppScrollingSlice";
import userInfoReducer from "./userInfoSlice";
import matchedAllVotersReducer from "./matchedAllVotersSlice";
import matchedSelectedVoterReducer from "./matchedSelectedVoterSlice";
import userLoadingReducer from "./userLoadingSlice";
import checkVoterInfoReducer from "./checkVoterInfoSlice";
import checkVoterInfoSubmittedReducer from "./checkVoterInfoSubmittedSlice";
import checkVoterResultsReducer from "./checkVoterResultsSlice";

// we are using redux toolkit and slices as described here: https://redux-toolkit.js.org/tutorials/quick-start

const rootReducer = combineReducers({
  // scrolling and screen size of app
  screenWidth: screenWidthReducer,
  freezeAppScrolling: freezeAppScrollingReducer,
  // logged in user and matched voter info for the signed in user.
  userInfo: userInfoReducer,
  matchedAllVoters: matchedAllVotersReducer,
  matchedSelectedVoter: matchedSelectedVoterReducer,
  userLoading: userLoadingReducer,
  // info for the voter we are looking up for sign in help
  checkVoterInfo: checkVoterInfoReducer,
  checkVoterInfoSubmitted: checkVoterInfoSubmittedReducer,
  checkVoterResults: checkVoterResultsReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "matchedSelectedVoter",
    "checkVoterInfo",
    "checkVoterInfoSubmitted",
    "checkVoterResults",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
});

// export const store = configureStore({
//   reducer: {
//     screenWidth: screenWidthReducer,
//     freezeAppScrolling: freezeAppScrollingReducer,
//     userInfo: userInfoReducer,
//     matchedAllVoters: matchedAllVotersReducer,
//     matchedSelectedVoter: matchedSelectedVoterReducer,
//     userLoading: userLoadingReducer,
//   },
// });

import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

import ResultsActiveHasContact from "./ResultsActiveHasContact";
import ResultsActiveNoContact from "./ResultsActiveNoContact";
import ResultsNotFound from "./ResultsNotFound";
import ResultsInactive from "./ResultsInactive";
import {
  getCheckVoterScenario,
  scenario_enums,
} from "../ComponentsAndUtils/utils";
import SignInNavBar from "../ComponentsAndUtils/SignInNavBar";
import ContactFooter from "../ComponentsAndUtils/ContactFooter";
import { mobileMarginVal } from "../../../styleConstants";

const ResultsWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: ${(props) =>
    props.$footerHeight}px; // this is the height of the contact footer including border.

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const SignInHelpVoterInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [footerHeight, setFooterHeight] = useState(null);
  const checkVoterResults = useSelector(
    (state) => state.checkVoterResults.value,
  );

  useEffect(() => {
    // If we've come to the help page from anywhere other than /signin/help, just redirect to /signin home.
    // This is to ensure user is walking through path as intended.
    if (location?.state?.from != "/signin/help") {
      navigate("/signin", { replace: true });
    }
  }, []);

  const cvScenario = getCheckVoterScenario(checkVoterResults);
  let resultGuts = <></>;

  if (cvScenario === scenario_enums.ACTIVE_HAS_CONTACT) {
    const phones = checkVoterResults?.data?.A?.phones || [];
    const emails = checkVoterResults?.data?.A?.emails || [];
    resultGuts = <ResultsActiveHasContact phones={phones} emails={emails} />;
  } else if (cvScenario === scenario_enums.ACTIVE_NO_CONTACT) {
    resultGuts = <ResultsActiveNoContact />;
  } else if (cvScenario === scenario_enums.INACTIVE) {
    resultGuts = <ResultsInactive />;
  } else if (cvScenario === scenario_enums.NOT_FOUND) {
    resultGuts = <ResultsNotFound />;
  } else if (cvScenario === scenario_enums.INVALID_STATE) {
    resultGuts = (
      <>
        This is not a California or Florida Zip Code. We cannot verify this
        voter.
      </>
    );
  } else if (cvScenario === scenario_enums.ERROR) {
    resultGuts = <>Some error occurred. Please try again later.</>;
  }

  return (
    <>
      <SignInNavBar includeVoterInfo={true} backPath={"/signin/help"} />
      <ResultsWrapper $footerHeight={footerHeight}>{resultGuts}</ResultsWrapper>
      <ContactFooter setFooterHeight={setFooterHeight} />
    </>
  );
};

SignInHelpVoterInfo.propTypes = {
  goBack: PropTypes.func,
  openSignInConditions: PropTypes.func,
};

export default SignInHelpVoterInfo;

import styled from "styled-components";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setCheckVoterDob,
  setCheckVoterFirst,
  setCheckVoterLast,
  setCheckVoterZip,
} from "../../../Redux/checkVoterInfoSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { navbarHeight, subNavBarZ } from "../../../styleConstants";
import { setCheckVoterInfoSubmitted } from "../../../Redux/checkVoterInfoSubmittedSlice";
import { setCheckVoterResults } from "../../../Redux/checkVoterResultsSlice";

const Wrapper = styled.div`
  // this shifts the page content slightly up, but fixes the text showing up between the navbars during scroll.
  margin-top: -1px;

  // this is a hacky fix.
  // when a slide in is open, sometimes this bar gets shifted up by 1px (happens from SignInHelpVoterUpdate, but not from SignInHelpVoterResults or SignInHelpUpdateInstructions)
  // So pages where this shift occurs can pass in the slide in open prop to be true, and this hacky fix stabalizes the navbar and avoids the shift up.
  margin-bottom: ${(props) => (props.$slideInOpen ? "-1px" : "0px")};
  padding-bottom: ${(props) => (props.$slideInOpen ? "1px" : "0px")};

  position: sticky;
  // position right below the main navbar, slightly covered by its 1px border.
  top: ${navbarHeight + 1}px;
  background: white;

  // matching navbar
  // needs to be more than sign in box
  z-index: ${subNavBarZ};
`;

const IconWrapper = styled.span`
  padding-right: 6px;
`;

const BackTopLine = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding-top: 2px;
  line-height: 26px;
  height: 26px;
  font-weight: 300;
  font-size: 14px;
`;

const BackText = styled.span`
  text-decoration: none;
  color: black;
  cursor: pointer;
`;

const VoterInfoWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 12px;
  font-weight: 300;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
`;

const Heavy = styled.span`
  font-weight: 400;
`;

const SmallLinksWrapper = styled.div`
  margin-top: -2px;
  font-weight: 300;
`;

const SmallLink = styled.span`
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

const SignInNavBar = ({ includeVoterInfo = true, backPath, slideInOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const checkVoterInfoSubmitted = useSelector(
    (state) => state.checkVoterInfoSubmitted.value,
  );
  const checkVoterResults = useSelector(
    (state) => state.checkVoterResults.value,
  );
  const first = checkVoterInfoSubmitted?.first;
  const last = checkVoterInfoSubmitted?.last;
  const dob = checkVoterInfoSubmitted?.dob;
  const zip = checkVoterInfoSubmitted?.zip;

  // this navbar appears on pages in sign in flow that need checkVoterInfoSubmitted and checkVoterResults (voter-info, update-instructions, voter-update)
  // navigate to sign in if I don't have this and I'm one of these pages that includes voter info.
  useEffect(() => {
    if (
      includeVoterInfo &&
      (!checkVoterInfoSubmitted ||
        !first ||
        !last ||
        !dob ||
        !zip ||
        !checkVoterResults)
    ) {
      navigate("/signin", { replace: true });
    }
  }, []);

  return (
    <Wrapper $slideInOpen={slideInOpen}>
      <BackTopLine>
        <BackText
          onClick={() => {
            // navigating back if backpath is same as where i came from.
            if (
              location?.state?.from &&
              backPath &&
              location?.state?.from === backPath
            ) {
              navigate(-1);
              // navigate to the backpath if i came from somewhere else.
            } else if (backPath) {
              navigate(backPath, {
                replace: true,
                state: { from: location.pathname },
              });
            }
          }}
        >
          <IconWrapper>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconWrapper>{" "}
          BACK
        </BackText>
      </BackTopLine>
      {includeVoterInfo && (
        <VoterInfoWrapper $slideInOpen={slideInOpen}>
          for{" "}
          <Heavy>
            {first} {last}
          </Heavy>
          , <Heavy>{dob}</Heavy>, <Heavy>{zip}</Heavy>
          <SmallLinksWrapper>
            <SmallLink
              onClick={() => {
                if (location?.state?.from === "/signin/help") {
                  navigate(-1);
                } else {
                  navigate("/signin/help", {
                    replace: true,
                    state: { fromEditOrClear: true },
                  });
                }
                // clear the submitted voter info and the fetched results
                dispatch(setCheckVoterInfoSubmitted(null));
                dispatch(setCheckVoterResults(null));
              }}
            >
              edit
            </SmallLink>{" "}
            /{" "}
            <SmallLink
              onClick={() => {
                if (location?.state?.from === "/signin/help") {
                  navigate(-1);
                } else {
                  navigate("/signin/help", {
                    replace: true,
                    state: { fromEditOrClear: true },
                  });
                }
                // clear the submitted voter info and the fetched results
                dispatch(setCheckVoterInfoSubmitted(null));
                dispatch(setCheckVoterResults(null));
                // clear everything we've typed.
                dispatch(setCheckVoterFirst(""));
                dispatch(setCheckVoterLast(""));
                dispatch(setCheckVoterDob(""));
                dispatch(setCheckVoterZip(""));
              }}
            >
              clear
            </SmallLink>
          </SmallLinksWrapper>
        </VoterInfoWrapper>
      )}
    </Wrapper>
  );
};

SignInNavBar.propTypes = {
  includeVoterInfo: PropTypes.bool,
  backPath: PropTypes.string,
  slideInOpen: PropTypes.bool,
};

export default SignInNavBar;

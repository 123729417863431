import styled from "styled-components";
import { useState } from "react";
import PropTypes from "prop-types";
import NativeSelect from "@mui/material/NativeSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
  overlayZ,
} from "../styleConstants";

export const caVlUpdateDate = "04/09/2024";
export const flVlUpdateDate = "07/31/2024";

const SubNote = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  @media screen and (min-width: 540px) {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

const GutsWrapper = styled.div`
  padding-left: ${mobileMarginVal}px;
  padding-right: ${mobileMarginVal}px;
  padding-top: 12px;
  padding-bottom: 16px;

  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    padding-left: ${desktopMarginVal}px;
    padding-right: ${desktopMarginVal}px;
    padding-top: 16px;
    padding-bottom: 24px;
  }

  // just eye-balled what is a good page width.
  max-width: 680px;
  // max-width: 70ch;
`;

const GrayBack = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  // needs to be in front than navbar and navigation menus
  z-index: ${overlayZ};
  background: rgba(95, 95, 95, 0.75);
`;

const Slide = styled.div`
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;

  overflow-y: auto;
  position: fixed;
  width: 100%;
  height: calc(100% - 18px);
  bottom: 0;

  background: white;

  animation: slide 0.5s ease;

  @keyframes slide {
    from {
      bottom: calc(-1 * (100% - 18px));
    }

    to {
      bottom: 0;
    }
  }

  // making a sideways slide in for wider screen widths.
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    width: calc(min((100% - 50px), max(70%, 850px)));
    // width: calc(min((100% - 50px), 1040px));
    height: 100%;
    right: 0;

    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    @keyframes slide {
      from {
        right: calc(-1 * min((100% - 50px), max(70%, 850px)));
      }

      to {
        right: 0;
      }
    }
  }
`;

const X = styled.div`
  font-size: 26px;
  color: black;
  margin-bottom: 16px;
`;

const ClickableX = styled.span`
  cursor: pointer;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background: white;

  padding-top: 16px;
  padding-bottom: 32px;

  border-bottom: 1px solid #e5e5e5;

  margin-left: ${mobileMarginVal}px;
  margin-right: ${mobileMarginVal}px;

  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    margin-left: ${desktopMarginVal}px;
    margin-right: ${desktopMarginVal}px;
  }
`;

const ExternalLink = styled.a`
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.64);
`;

const stateEnums = {
  SELECT: "SELECT",
  CA: "CA",
  FL: "FL",
  OTHER: "OTHER",
};

const ConditionsSlideIn = ({ closeConditions }) => {
  const [selectedState, setSelectedState] = useState(stateEnums.SELECT);

  let contents = <></>;
  if (selectedState === stateEnums.SELECT) {
    contents = <></>;
  } else if (selectedState === stateEnums.OTHER) {
    contents = (
      <>
        Only California and Florida can sign in to Verified Voice at this time.
        We are unable to verify voter registrations from other states.
      </>
    );
  } else {
    const caSelected = selectedState === stateEnums.CA;
    const state = caSelected ? "California" : "Florida";
    const vlUpdateDate = caSelected ? caVlUpdateDate : flVlUpdateDate;
    contents = (
      <>
        <Bold>
          1. You must be a registered {state} voter as of {vlUpdateDate}.
        </Bold>
        <SubNote>
          The {state} voter list we use for voter verification is a snapshot of
          the {"state's"} voter registrations, as they were, on {vlUpdateDate}.{" "}
          Only {state} voters who are on this list can sign in to Verified
          Voice. We routinely update the voter list to include up-to-date voter
          registrations.
        </SubNote>
        <br />
        <Bold>
          2. Your voter registration, as it was on {vlUpdateDate}, must include
          an SMS-capable phone number or email address.
        </Bold>
        <SubNote>
          We verify your voter registration by texting a one-time password or
          emailing a sign-in link to the phone number or email address you
          registered to vote with. So while {"it's"} generally not required to
          register to vote with an SMS-capable phone number or email address, it
          is to sign in to Verified Voice.
        </SubNote>
        <br />
        {caSelected ? (
          <>
            <Bold>
              3. Your voter status, as it was on {vlUpdateDate}, is active.
            </Bold>
            <SubNote>
              All California voter registrations have active status by default.
              A voter may be given inactive status if the state believes they
              may have moved or have not voted in 2 federal general elections.{" "}
              <ExternalLink
                href="https://registertovote.ca.gov/"
                target="_blank"
              >
                Here
              </ExternalLink>{" "}
              you can check your voter status and update it to active by
              (re-)registering to vote.
            </SubNote>
          </>
        ) : (
          <>
            <Bold>
              3. Your voter status, as it was on {vlUpdateDate}, is active.
            </Bold>
            <SubNote>
              All Florida voter registrations have active status by default. A
              voter may be given inactive status if the state believes they may
              have moved.{" "}
              <ExternalLink
                href="https://registertovoteflorida.gov/home"
                target="_blank"
              >
                Here
              </ExternalLink>{" "}
              you can check and update your voter status.
            </SubNote>
          </>
        )}
        <br />
        {caSelected ? (
          <>
            <Bold>
              4. Your voter information, as it was on {vlUpdateDate}, is not
              confidential.
            </Bold>
            <SubNote>
              Under California law (
              <ExternalLink
                href="https://www.sos.ca.gov/registries/safe-home/laws/confidential-voter-registration-law"
                target="_blank"
              >
                Elections Code sections 2166, 2166.5, 2166.7, or 2166.8
              </ExternalLink>
              ), confidential voter information is not available to and cannot
              be verified by Verified Voice.
            </SubNote>
          </>
        ) : (
          <>
            <Bold>
              4. Your voter information, as it was on {vlUpdateDate}, is part of
              the puclic record.
            </Bold>
            <SubNote>
              Under Florida law,{" "}
              <ExternalLink
                href="https://dos.myflorida.com/elections/for-voters/voter-registration/voter-information-as-a-public-record/"
                target="_blank"
              >
                voter information is public record with few excpetions
              </ExternalLink>
              . If your voter information is exempt from being part of the
              public record, we cannot verify your voter registration.
            </SubNote>
          </>
        )}
      </>
    );
  }

  return (
    <GrayBack>
      <Slide>
        <StickyHeader>
          <X>
            <ClickableX>
              <FontAwesomeIcon icon={faXmark} onClick={closeConditions} />
            </ClickableX>
          </X>
          To sign in to Verified Voice as a registered voter of{" "}
          <NativeSelect
            sx={{
              fontFamily: `"Inter", sans-serif`,
              letterSpacing: "normal",
              fontWeight: 500,
            }}
            onChange={(event) => {
              setSelectedState(event.target.value);
            }}
          >
            <option value={stateEnums.SELECT}>—select your state—</option>
            <option value={stateEnums.CA}>California</option>
            <option value={stateEnums.FL}>Florida</option>
            <option value={stateEnums.OTHER}>any other state</option>
          </NativeSelect>
          :
        </StickyHeader>
        <GutsWrapper>{contents}</GutsWrapper>
      </Slide>
    </GrayBack>
  );
};

ConditionsSlideIn.propTypes = {
  closeConditions: PropTypes.func,
};

export default ConditionsSlideIn;

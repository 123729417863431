import { useState, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccountMenuMobile from "./AccountMenuMobile";
import AccountMenuDesktop from "./AccountMenuDesktop";
import NavigationMobile from "./NavigationMobile/NavigationMobile";
import NavigationDesktop from "./NavigationDesktop";
import NavbarDesktopButton from "./NavbarDesktopButton";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mainNavBarZ,
  mobileMarginVal,
  navbarHeight,
} from "../../styleConstants";

// just the top header with the hamburger menu
const NavBarWrapper = styled.div`
  position: sticky;
  top: 0;
  background: white;

  display: flex;
  height: ${navbarHeight}px;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e5e5e5;

  // needs to be more than sign in box
  z-index: ${mainNavBarZ};

  margin-left: ${mobileMarginVal}px;
  margin-right: ${mobileMarginVal}px;

  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    margin-left: ${desktopMarginVal}px;
    margin-right: ${desktopMarginVal}px;
  }
`;

const AccountLogo = styled.div`
  background: black;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 18px;

  text-align: center;
  line-height: 36px;

  cursor: pointer;
`;

const Navbar = () => {
  const navigate = useNavigate();

  const screenWidth = useSelector((state) => state.screenWidth.value);
  let isMobile = screenWidth < 540;

  const userInfo = useSelector((state) => state.userInfo.value);
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  let accountLogo = null;
  // if we have a signed in user, make the account logo the first char of email or phone
  if (userInfo?.email) {
    accountLogo = userInfo.email.charAt(0);
  } else if (userInfo?.phone) {
    // we want the first number after the +1
    accountLogo = userInfo.phone.charAt(2);
  }
  // if there is a selected voter, make the account logo the first char of the first name, or in the rare case of no first name, use the first char of the last name
  if (selectedVoter) {
    if (selectedVoter.first) {
      accountLogo = selectedVoter.first.charAt(0);
    } else if (selectedVoter.last) {
      accountLogo = selectedVoter.last.charAt(0);
    }
  }
  // a ref on account logo to pass to open account menu.
  const logoRef = useRef(null);

  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const showMobileAccountMenu = accountMenuOpen && isMobile;
  const showDesktopAccountMenu = accountMenuOpen && !isMobile;

  return (
    <>
      <NavBarWrapper>
        {isMobile ? <NavigationMobile /> : <NavigationDesktop />}
        {accountLogo && (
          <AccountLogo
            ref={logoRef}
            onClick={() => {
              setAccountMenuOpen(!accountMenuOpen);
            }}
          >
            {accountLogo}
          </AccountLogo>
        )}
        {!accountLogo && !isMobile && (
          <NavbarDesktopButton
            text={"SIGN IN"}
            onClick={() => {
              navigate("/signin");
            }}
          />
        )}
        {/* only open account menu if account icon is clicked. */}
        {showMobileAccountMenu && (
          <AccountMenuMobile
            accountLogo={accountLogo}
            closeMenu={() => {
              setAccountMenuOpen(false);
            }}
          />
        )}
        {showDesktopAccountMenu && (
          <AccountMenuDesktop
            closeMenu={() => {
              setAccountMenuOpen(false);
            }}
            // need to pass this so that we exclude it from the 'click away then close menu' logic
            logoRef={logoRef}
          />
        )}
      </NavBarWrapper>
    </>
  );
};

export default Navbar;

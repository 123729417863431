import PropTypes from "prop-types";
import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ActionButton from "../ComponentsAndUtils/ActionButton";
import SignInBoxPhone from "../ComponentsAndUtils/SignInBox/SignInBoxPhone";
import SignInBoxEmail from "../ComponentsAndUtils/SignInBox/SignInBoxEmail";
import { isCaZip } from "../ComponentsAndUtils/utils";
import {
  caVlUpdateDate,
  flVlUpdateDate,
} from "../../../Components/ConditionsSlideIn";

const ListedContactWrapper = styled.div`
  margin-left: 24px;
  margin-top: 10px;
  margin-bottom: 26px;
`;

const BoldLight = styled.span`
  font-weight: 500;

  // this will make the masking with astrisks consistent.
  font-variant-ligatures: no-contextual;
`;

const CantVerifyWrapper = styled.div`
  margin-top: 14px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.64);
  font-weight: 400;
`;

const CantVerifyText = styled.span`
  // display: inline-block;
`;

const ButtonWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: center; // we want a center align on mobile view
  // widths for single vs double button. Single should have no container restriction.
  max-width: ${(props) => (props.$singleButton ? 680 : 480)}px;
  // props.$cantVerifyTextWidth
  // 370

  // we want a left align on desktop view.
  @media screen and (min-width: 540px) {
    justify-content: start;
  }
`;

const ButtonSpace = styled.div`
  min-width: 8px;

  @media screen and (min-width: 540px) {
    min-width: 12px;
  }
`;

const SubNote = styled.div`
  margin-top: 4px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const ResultsActiveHasContact = ({ phones, emails }) => {
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
  // JUST FOR TESTING
  // emails = [];
  // emails = ["r***********@g****.com"];
  // emails = ["r***********@g****.com", "g*******@g****.com"];
  // emails = [
  //   "r***********@g****.com",
  //   "g*******@g****.com",
  //   "r****@v************.org",
  // ];
  // phones = [];
  // phones = ["(6**) ***-**89"];
  // phones = ["(6**) ***-**89", "(6**) ***-**62"];
  // phones = ["(6**) ***-**89", "(6**) ***-**62", "(4**) ***-**06"];
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***

  const location = useLocation();
  const [signInMethod, setSignInMethod] = useState(null);
  const checkVoterInfo = useSelector((state) => state.checkVoterInfo.value);
  const zip = checkVoterInfo.zip;
  const isCa = isCaZip(zip);

  const singleOnlyFound = phones.length + emails.length === 1;
  const exactlyTwoFound = phones.length + emails.length === 2;
  const phonesOnly = emails.length === 0;
  const emailsOnly = phones.length === 0;

  let contactsArticle = "any of these listed";
  if (exactlyTwoFound) {
    contactsArticle = "either of these listed";
  } else if (singleOnlyFound) {
    contactsArticle = "this";
  }

  let contactsCategory = singleOnlyFound ? "contact" : "contacts";
  if (phonesOnly) {
    contactsCategory = singleOnlyFound ? "phone number" : "phone numbers";
  } else if (emailsOnly) {
    contactsCategory = singleOnlyFound ? "email address" : "email addresses";
  }

  const cantVerifyMessage = (
    <CantVerifyText>
      If you do not possess or cannot verify {contactsArticle}{" "}
      {contactsCategory}
      {/* If only phones, put a "via SMS" as part of message */}
      {phonesOnly && " via SMS"},{" "}
      <NavLink
        to="/signin/help/update-instructions"
        state={{ from: location.pathname }}
        style={{ color: "rgba(0, 0, 0, 0.64)" }}
      >
        click here <FontAwesomeIcon icon={faArrowRight} />
      </NavLink>
      .
    </CantVerifyText>
  );

  let buttonBar = <></>;
  if (phonesOnly) {
    buttonBar = (
      <ButtonWrapper $singleButton={true}>
        <ActionButton
          text={"VERIFY PHONE"}
          onClick={() => {
            setSignInMethod("phone");
          }}
          isSingleAction={true}
          faIconRight={faArrowRight}
        />
      </ButtonWrapper>
    );
  } else if (emailsOnly) {
    buttonBar = (
      <ButtonWrapper $singleButton={true}>
        <ActionButton
          text={"VERIFY EMAIL"}
          onClick={() => {
            setSignInMethod("email");
          }}
          isSingleAction={true}
          faIconRight={faArrowRight}
        />
      </ButtonWrapper>
    );
  } else {
    buttonBar = (
      <ButtonWrapper $singleButton={false}>
        <ActionButton
          text={"VERIFY PHONE"}
          onClick={() => {
            setSignInMethod("phone");
          }}
          isSingleAction={false}
          faIconRight={faArrowRight}
        />
        <ButtonSpace />
        <ActionButton
          text={"VERIFY EMAIL"}
          onClick={() => {
            setSignInMethod("email");
          }}
          isSingleAction={false}
          faIconRight={faArrowRight}
        />
      </ButtonWrapper>
    );
  }

  let boxToShow = <></>;
  if (signInMethod === "phone") {
    boxToShow = (
      <SignInBoxPhone
        onCancel={() => {
          setSignInMethod(null);
        }}
      />
    );
  } else if (signInMethod === "email") {
    boxToShow = (
      <SignInBoxEmail
        fromEmailRedirect={false}
        onCancel={() => {
          setSignInMethod(null);
        }}
      />
    );
  }

  return (
    <>
      You have the following on file:
      <SubNote>
        (as of {isCa ? caVlUpdateDate : flVlUpdateDate}, our last{" "}
        {isCa ? "California" : "Florida"} voter list update)
      </SubNote>
      <ListedContactWrapper>
        {phones.map((phone, i) => (
          <div key={`${phone}${i}`}>
            <BoldLight>{phone}</BoldLight>
            <br />
          </div>
        ))}
        {emails.map((email, i) => (
          <div key={`${email}${i}`}>
            <BoldLight>{email}</BoldLight>
            <br />
          </div>
        ))}
      </ListedContactWrapper>
      {signInMethod ? (
        boxToShow
      ) : (
        <>
          Verify {singleOnlyFound ? "this" : "one of these"} {contactsCategory}{" "}
          to sign in.
          {buttonBar}
          <CantVerifyWrapper>{cantVerifyMessage}</CantVerifyWrapper>
        </>
      )}
    </>
  );
};

ResultsActiveHasContact.propTypes = {
  phones: PropTypes.array,
  emails: PropTypes.array,
};

export default ResultsActiveHasContact;

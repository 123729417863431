import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setCheckVoterDob,
  setCheckVoterFirst,
  setCheckVoterLast,
  setCheckVoterZip,
} from "../Redux/checkVoterInfoSlice";
import { setCheckVoterInfoSubmitted } from "../Redux/checkVoterInfoSubmittedSlice";
import { setCheckVoterResults } from "../Redux/checkVoterResultsSlice";

// this component contains hooks that need to be in the router.
// this specific hook clears voter info once we've left the relevant sign-in paths.
const NavigationHooks = () => {
  const location = useLocation();
  const lp = location?.pathname || "";
  const dispatch = useDispatch();
  // console.log(lp);
  // console.log(location);

  const checkVoterInfoPaths = [
    "/signin/help",
    "/signin/help/voter-info",
    "/signin/help/update-instructions",
    "/signin/help/voter-update",
  ];
  const checkVoterInfoSubmittedPaths = [
    "/signin/help/voter-info",
    "/signin/help/update-instructions",
    "/signin/help/voter-update",
  ];

  useEffect(() => {
    // If I am not in one of the paths that needs/uses check voter info, just clear it since it means I've left that flow.
    if (!checkVoterInfoPaths.includes(lp)) {
      dispatch(setCheckVoterFirst(""));
      dispatch(setCheckVoterLast(""));
      dispatch(setCheckVoterDob(""));
      dispatch(setCheckVoterZip(""));
    }

    // If I am not in one of the paths that needs/uses check voter info submitted, just clear it since it means I've left that flow.
    // check voter submitted is the actual info that was used to fetch voter results, as opposed to what is typed.
    if (!checkVoterInfoSubmittedPaths.includes(lp)) {
      dispatch(setCheckVoterInfoSubmitted(null));
      dispatch(setCheckVoterResults(null));
    }
  }, [lp]);

  return <></>;
};

export default NavigationHooks;

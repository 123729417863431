import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;

const InnerWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const SpaceBetween = styled.div`
  margin-bottom: 18px;
`;

const LoadingGuts = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        Verifying your voter registration...
        <SpaceBetween />
        <TailSpin color="black" height={80} width={80} />
      </InnerWrapper>
    </Wrapper>
  );
};

export default LoadingGuts;

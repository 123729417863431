import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null, // when null, means not fetched, once the matched voters are fetched, this will be an array
};

export const matchedAllVotersSlice = createSlice({
  name: "matchedAllVoters",
  initialState,
  reducers: {
    setMatchedAllVoters: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMatchedAllVoters } = matchedAllVotersSlice.actions;

export default matchedAllVotersSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const freezeAppScrollingSlice = createSlice({
  name: "freezeAppScrolling",
  initialState,
  reducers: {
    setFreezeAppScrolling: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFreezeAppScrolling } = freezeAppScrollingSlice.actions;

export default freezeAppScrollingSlice.reducer;

// import { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { allPetitions } from "./petitionList";

const Wrapper = styled.div`
  padding-top: 12px;

  @media screen and (min-width: 540px) {
    padding-top: 54px;
  }
`;

const PetitionTile = styled.div`
  font-weight: 500;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 4px;
  margin-top: 12px;
  color: black;

  // to not make it too wide on desktop view.
  max-width: 814px;

  cursor: pointer;
  &:hover {
    border: 1px solid black;
  }
`;

const Bold = styled.span`
  font-weight: 500;
`;

const SubNote = styled.div`
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  font-weight: 400;
`;

const SubmissionWrapper = styled.div`
  margin-top: 18px;
`;

const Petitions = () => {
  return (
    <Wrapper>
      <Bold>Browse petitions below.</Bold> Select one to read more and voice
      your opinion.
      {allPetitions.map((petition) => (
        <NavLink
          key={petition.url}
          to={`/petitions/${petition.url}`}
          style={{ textDecoration: "none" }}
        >
          <PetitionTile>
            {petition.uiTitle}
            <SubNote>{petition.tileDescription}</SubNote>
          </PetitionTile>
        </NavLink>
      ))}
      <SubmissionWrapper>
        Email <Bold>info@verifiedvoice.org</Bold> to add your own petition to
        Verified Voice.
      </SubmissionWrapper>
    </Wrapper>
  );
};

export default Petitions;

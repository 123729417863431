import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import styled from "styled-components";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ActionButton from "./ComponentsAndUtils/ActionButton";

import ConditionsSlideIn from "../../Components/ConditionsSlideIn";
import SignInNavBar from "./ComponentsAndUtils/SignInNavBar";
import ContactFooter from "./ComponentsAndUtils/ContactFooter";
import { mobileMarginVal } from "../../styleConstants";

const Wrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: ${(props) =>
    props.$footerHeight}px; // this is the height of the contact footer including border.

  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const SubNote = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
`;
const ListedContactWrapper = styled.div`
  margin-left: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const ListedContact = styled.span`
  // this will make the masking with astrisks consistent.
  font-variant-ligatures: no-contextual;
`;

const ClickBack = styled.span`
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

const SignInHelpUpdateInstructions = () => {
  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);
  const [footerHeight, setFooterHeight] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const checkVoterResults = useSelector(
    (state) => state.checkVoterResults.value,
  );

  useEffect(() => {
    // If we've come to the help page from anywhere other than /signin/help/voter-info, just redirect to /signin home.
    // This is to ensure user is walking through path as intended.
    if (location?.state?.from != "/signin/help/voter-info") {
      navigate("/signin", { replace: true });
    }
  }, []);

  const phones = checkVoterResults?.data?.A?.phones || [];
  const emails = checkVoterResults?.data?.A?.emails || [];
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
  // JUST FOR TESTING
  // const emails = [];
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***

  useEffect(() => {
    // this component should only be displayed if I have at least one active phone or email.
    if (phones.length === 0 && emails.length === 0) {
      navigate("/signin/help/voter-info", { replace: true });
    }
  }, [phones, emails]);

  let contactArticle = "these contacts";
  let contactArticle2 = "one of them";
  if (phones.length + emails.length === 1) {
    contactArticle = "this contact";
    contactArticle2 = "it";
  }
  const phonesOnly = emails.length === 0;

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <SignInNavBar
        includeVoterInfo={true}
        backPath={"/signin/help/voter-info"}
        slideInOpen={signInConditionsOpen}
      />
      <Wrapper $stopScroll={signInConditionsOpen} $footerHeight={footerHeight}>
        Your voter registration must include an SMS-capable phone number and/or
        an email address that you possess and can verify for you to sign in to
        Verified Voice. Update your voter registration to include this.
        <SubNote>
          <LinkButton
            onClick={() => {
              setSignInConditionsOpen(true);
            }}
          >
            See {`what's`} required
          </LinkButton>{" "}
          to sign in.
        </SubNote>
        <ButtonWrapper>
          <ActionButton
            text="UPDATE VOTER REGISTRATION"
            faIconRight={faArrowRight}
            linkPath={"/signin/help/voter-update"}
            linkFrom={location.pathname}
          />
        </ButtonWrapper>
        <SubNote>
          You currently have {contactArticle} on file:
          <br />
          <ListedContactWrapper>
            {phones.map((phone, i) => (
              <div key={`${phone}${i}`}>
                <ListedContact>{phone}</ListedContact>
                <br />
              </div>
            ))}
            {emails.map((email, i) => (
              <div key={`${email}${i}`}>
                <ListedContact>{email}</ListedContact>
                <br />
              </div>
            ))}
          </ListedContactWrapper>
          <ClickBack
            // check if previous page is where I expect to come from before going back.
            onClick={() => {
              if (location?.state?.from === "/signin/help/voter-info") {
                navigate(-1);
              } else {
                navigate("/signin/help/voter-info", {
                  replace: true,
                  state: { from: location.pathname },
                });
              }
            }}
          >
            Click here
          </ClickBack>{" "}
          if you can verify {contactArticle2}
          {phonesOnly && " via SMS"} now.
        </SubNote>
        <ContactFooter setFooterHeight={setFooterHeight} />
      </Wrapper>
    </>
  );
};

SignInHelpUpdateInstructions.propTypes = {
  phones: PropTypes.array,
  emails: PropTypes.array,
};

export default SignInHelpUpdateInstructions;

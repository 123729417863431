import styled from "styled-components";
import HomeButton from "./HomeButton";
import { useNavigate } from "react-router-dom";
import HomeButtonSecondary from "./HomeButtonSecondary";

const Wrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SubTitle = styled.div`
  font-size: 20px;
`;

const ButtonWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
`;

const SubNote = styled.div`
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
`;

const HomeMobile = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Title>Verified Voice</Title>
      <SubTitle>Online petitions for registered voters only.</SubTitle>
      <br />
      <br />
      Participate by signing in as a verified registered voter:
      <ButtonWrapper>
        <HomeButton
          text="SIGN IN"
          onClick={() => {
            navigate("/signin");
          }}
        />
      </ButtonWrapper>
      <SubNote>
        Via simple phone or email verification. No other information is
        required.
      </SubNote>
      <br />
      <br />
      <br />
      <br />
      <ButtonWrapper>
        <HomeButtonSecondary
          text="Browse Petitions"
          onClick={() => {
            navigate("/petitions");
          }}
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <HomeButtonSecondary
          text="About Verified Voice"
          onClick={() => {
            navigate("/about");
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default HomeMobile;

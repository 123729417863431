import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
  subNavBarZ,
} from "../../../styleConstants";

const Wrapper = styled.div`
  // this is for mobile margin.
  width: calc(100% - ${2 * mobileMarginVal}px);
  // desktop margin for screen widths at the cutoff or greater
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    width: calc(100% - ${2 * desktopMarginVal}px);
  }

  position: fixed;
  bottom: 0;
  background: white;

  // matching navbar
  // needs to be more than sign in box
  z-index: ${subNavBarZ};

  border-top: 1px solid rgba(0, 0, 0, 0.07);
  padding-top: 7px;
  padding-bottom: 10px;

  font-weight: 300;
  font-size: 14px;
  // line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
`;

const Gap = styled.div`
  margin-bottom: 1px;
`;

const Heavier = styled.span`
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
`;

const Lighter = styled.span`
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
`;

const ContactFooter = ({ setFooterHeight }) => {
  const contactFooterRef = useRef(null);
  useEffect(() => {
    // setting the total height, including the 1px border
    const heightWithBorder = contactFooterRef.current.clientHeight + 1;
    setFooterHeight && setFooterHeight(heightWithBorder);
  }, [contactFooterRef]);

  return (
    <Wrapper ref={contactFooterRef}>
      Reach out for help or for any reason:
      <Gap />
      <Heavier>
        (650) 646-4333 <Lighter>|</Lighter> info@verifiedvoice.org
      </Heavier>
    </Wrapper>
  );
};

ContactFooter.propTypes = {
  setFooterHeight: PropTypes.func,
};

export default ContactFooter;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  border-radius: 2px;
  border: 1px solid black;
  text-align: center;
  color: white;
  background: black;
  font-size: 14px;
  letter-spacing: 0.12em;
  font-weight: 600;

  height: 46px;
  line-height: 46px;
  width: 100%; //180px;
  max-width: 340px;

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: pointer;
  &:hover {
    background: white;
    color: black;
    border: 1px solid black;
  }
`;

const HomeButton = ({ text, onClick }) => {
  return <Wrapper onClick={() => onClick()}>{text}</Wrapper>;
};

HomeButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default HomeButton;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: window.innerWidth,
};

export const screenWidthSlice = createSlice({
  name: "screenWidth",
  initialState,
  reducers: {
    setScreenWidth: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setScreenWidth } = screenWidthSlice.actions;

export default screenWidthSlice.reducer;

import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItem = styled.div`
  padding-right: 60px;
`;

const getMenuItemStyle = ({ isActive }) => {
  return {
    textDecoration: "none",
    color: isActive ? "black" : "#5c5c5c",
    fontWeight: isActive ? "500" : "400",
  };
};

const NavigationDesktop = () => {
  return (
    <Wrapper>
      <MenuItem>
        <NavLink to="/" style={getMenuItemStyle}>
          Home
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/petitions" style={getMenuItemStyle}>
          Petitions
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/about" style={getMenuItemStyle}>
          About
        </NavLink>
      </MenuItem>
    </Wrapper>
  );
};

export default NavigationDesktop;

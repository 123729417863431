import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  border-radius: 6px;
  border: 1px solid black;
  text-align: center;
  color: white;
  background: black;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 500;

  height: 28px;
  line-height: 28px;
  width: 96px;

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: pointer;
`;

const NavbarDesktopButton = ({ text, onClick }) => {
  return <Wrapper onClick={() => onClick()}>{text}</Wrapper>;
};

NavbarDesktopButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavbarDesktopButton;

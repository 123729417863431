import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null, // when null, means no voter selected.
};

export const matchedSelectedVoterSlice = createSlice({
  name: "matchedSelectedVoter",
  initialState,
  reducers: {
    setMatchedSelectedVoter: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMatchedSelectedVoter } = matchedSelectedVoterSlice.actions;

export default matchedSelectedVoterSlice.reducer;

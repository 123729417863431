import { useEffect, useState } from "react";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import PropTypes, { bool } from "prop-types";
import { useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";

import { sendSignInLinkToEmail, signInWithEmailLink } from "firebase/auth";
import { fireAuth } from "../../../../Firebase/firebase";
import FormButton from "./SignInBoxButton";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;

  max-width: 420px;
`;

const InstructionsLabel = styled.div`
  padding-bottom: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
`;

const FooterWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  align-items: center;

  max-width: 420px;
`;

const FieldWrapper = styled.div`
  max-width: 432px;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const ErrorFooter = styled.div`
  display: flex;
  justify-content: start;
  padding-top: 24px;
  align-items: center;

  max-width: 420px;
`;

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focuscolor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focuscolor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focuscolor,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focuscolor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focuscolor,
    },
  },
}));

const vsEnums = {
  INPUT_FORM: "INPUT_FORM",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_LOADING: "SUBMIT_LOADING",
  TROUBLE_RECEIVING: "TROUBLE_RECEIVING",
  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  FINISH_SUCCESS: "FINISH_SUCCESS",
  FINISH_ERROR: "FINISH_ERROR",
};

const SignInBoxEmail = ({ fromEmailRedirect, onCancel }) => {
  const navigate = useNavigate();

  // the text input that user is typing for email
  const [userEmail, setUserEmail] = useState("");
  // the text input that user is typing for email confirmation
  const [userEmailConfirmation, setUserEmailConfirmation] = useState("");

  // This status tells us which view to show
  // can be 'inputForm' (for show input form), 'submitSuccess', 'submitLoading', 'troubleReceiving'
  // use enums above.
  const [viewStatus, setViewStatus] = useState(vsEnums.INPUT_FORM);

  // this is the error passed from firebase if there is an error in submission
  const [firebaseSubmissionError, setFirebaseSubmissionError] = useState(null);
  // this is the error passed from firebase if there is an error finishing signing in.
  const [firebaseSignInFinishError, setFirebaseSignInFinishError] =
    useState(null);

  // submitting the email, this will send the link.
  const handleSubmit = (email) => {
    setViewStatus(vsEnums.SUBMIT_LOADING);
    const actionCodeSettings = {
      url: `${window.location.origin}/signin`,
      handleCodeInApp: true,
    };
    sendSignInLinkToEmail(fireAuth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        setViewStatus(vsEnums.SUBMIT_SUCCESS);
        setFirebaseSubmissionError(null);
      })
      .catch((error) => {
        if (error) {
          setViewStatus(vsEnums.INPUT_FORM);
          setFirebaseSubmissionError(error);
        }
      });
  };

  const finishSignIn = (submittedEmail) => {
    setViewStatus(vsEnums.LOADING);
    signInWithEmailLink(fireAuth, submittedEmail, window.location.href)
      .then(() => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        setViewStatus(vsEnums.FINISH_SUCCESS);
      })
      .catch((error) => {
        setFirebaseSignInFinishError(error);
        if (error.code === "auth/invalid-email") {
          setViewStatus(vsEnums.CONFIRM_EMAIL);
        } else {
          setViewStatus(vsEnums.FINISH_ERROR);
        }
      });
  };

  useEffect(() => {
    if (fromEmailRedirect) {
      let submittedEmail = window.localStorage.getItem("emailForSignIn");
      if (submittedEmail) {
        finishSignIn(submittedEmail);
      } else {
        setViewStatus(vsEnums.CONFIRM_EMAIL);
      }
    }
  }, []);

  const inputFormGuts = (
    <>
      <InstructionsLabel>Enter your email:</InstructionsLabel>
      <FieldWrapper>
        <CssTextField
          sx={{
            width: "100%",
            height: "72px",
          }}
          InputLabelProps={{
            style: { fontFamily: `"Inter", sans-serif` },
          }}
          InputProps={{
            style: { fontFamily: `"Inter", sans-serif` },
          }}
          focuscolor="black"
          error={firebaseSubmissionError !== null}
          helperText={
            (firebaseSubmissionError?.code === "auth/invalid-email" &&
              "Enter a valid email address.") ||
            firebaseSubmissionError?.message
          }
          label="Email"
          variant="standard"
          type="email"
          name="email"
          value={userEmail}
          onChange={(event) => {
            setUserEmail(event.target.value);
            // setErrorWithEmailInput(false);
            setFirebaseSubmissionError(null);
          }}
        />

        <ButtonWrapper>
          <FormButton
            isPrimary={false}
            text={"CANCEL"}
            onClick={() => {
              onCancel();
            }}
          />
          <FormButton
            isPrimary={true}
            text={"NEXT"}
            onClick={() => {
              handleSubmit(userEmail);
            }}
          />
        </ButtonWrapper>
      </FieldWrapper>
    </>
  );

  const emailSentGuts = (
    <>
      <InstructionsLabel>
        <Bold>Sign-in email sent.</Bold>
      </InstructionsLabel>
      A sign-in email with additional instructions was sent to{" "}
      <Bold>{userEmail}</Bold>. Check your email to complete sign-in.
      <FooterWithLink>
        <a
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "14px",
          }}
          onClick={() => {
            setViewStatus(vsEnums.TROUBLE_RECEIVING);
          }}
        >
          Trouble getting email?
        </a>
        <FormButton
          isPrimary={true}
          text={"BACK"}
          onClick={() => {
            setUserEmail("");
            setViewStatus(vsEnums.INPUT_FORM);
          }}
        />
      </FooterWithLink>
    </>
  );

  const troubleReceivingEmailGuts = (
    <>
      <InstructionsLabel>
        <Bold>Trouble getting email?</Bold>
      </InstructionsLabel>
      Try these common fixes:
      <ul>
        <li>Check if the email was marked as spam or filtered.</li>
        <li>Check your internet connection.</li>
        <li>Check that you did not misspell your email.</li>
        <li>
          Check that your inbox space is not running out or other inbox settings
          related issues.
        </li>
      </ul>
      If the steps above {"didn't"} work, you can resend the email. Note that
      this will deactivate the link in the older email.
      <FooterWithLink>
        <a
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "14px",
          }}
          onClick={() => {
            handleSubmit(userEmail);
          }}
        >
          Resend
        </a>
        <FormButton
          isPrimary={true}
          text={"BACK"}
          onClick={() => {
            setViewStatus(vsEnums.INPUT_FORM);
          }}
        />
      </FooterWithLink>
    </>
  );

  const loadingGuts = (
    <LoaderWrapper>
      <TailSpin color="black" height={32} width={32} />
    </LoaderWrapper>
  );

  const finishErrorGuts = (
    <>
      <InstructionsLabel>
        <Bold>Error</Bold>
      </InstructionsLabel>
      The following error occurred: <br />
      {firebaseSignInFinishError?.message}
      {firebaseSignInFinishError?.code === "auth/invalid-action-code" && (
        <>
          <br />
          <br />
          Use the most recent, valid sign-in link. Older sign-in links are
          invalid.
        </>
      )}
      <ErrorFooter>
        <FormButton
          isPrimary={true}
          text={"TRY AGAIN"}
          onClick={() => {
            navigate("/signin");
          }}
        />
      </ErrorFooter>
    </>
  );

  const confirmEmailGuts = (
    <>
      <InstructionsLabel>
        <Bold>Confirm your email:</Bold>
      </InstructionsLabel>
      <FieldWrapper>
        <CssTextField
          sx={{
            width: "100%",
            height: "72px",
          }}
          InputLabelProps={{
            style: { fontFamily: `"Inter", sans-serif` },
          }}
          InputProps={{
            style: { fontFamily: `"Inter", sans-serif` },
          }}
          focuscolor="black"
          error={firebaseSignInFinishError !== null}
          helperText={
            (firebaseSignInFinishError?.code === "auth/invalid-email" &&
              "Email did not match. Try again.") ||
            firebaseSubmissionError?.message
          }
          label="Email"
          variant="standard"
          type="email"
          name="email"
          value={userEmailConfirmation}
          onChange={(event) => {
            setUserEmailConfirmation(event.target.value);
            setFirebaseSignInFinishError(null);
          }}
        />
        <ButtonWrapper>
          <FormButton
            isPrimary={false}
            text={"CANCEL"}
            onClick={() => {
              navigate("/signin");
            }}
          />
          <FormButton
            isPrimary={true}
            text={"CONFIRM"}
            onClick={() => {
              finishSignIn(userEmailConfirmation);
            }}
          />
        </ButtonWrapper>
      </FieldWrapper>
    </>
  );

  let gutsToDisplay = inputFormGuts;
  if (viewStatus === vsEnums.SUBMIT_LOADING) {
    gutsToDisplay = loadingGuts;
  } else if (viewStatus === vsEnums.SUBMIT_SUCCESS) {
    gutsToDisplay = emailSentGuts;
  } else if (viewStatus === vsEnums.TROUBLE_RECEIVING) {
    gutsToDisplay = troubleReceivingEmailGuts;
  } else if (viewStatus === vsEnums.CONFIRM_EMAIL) {
    gutsToDisplay = confirmEmailGuts;
  } else if (viewStatus === vsEnums.FINISH_ERROR) {
    gutsToDisplay = finishErrorGuts;
  }

  return gutsToDisplay;
};

SignInBoxEmail.propTypes = {
  fromEmailRedirect: bool,
  onCancel: PropTypes.func,
};

export default SignInBoxEmail;

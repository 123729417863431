import { useSelector } from "react-redux";
import HomeMobile from "./HomeMobile/HomeMobile";
import HomeDesktop from "./HomeDesktop/HomeDesktop";

const Home = () => {
  const screenWidth = useSelector((state) => state.screenWidth.value);
  // I just eye-balled a break point
  if (screenWidth < 540) {
    return <HomeMobile />;
  } else {
    return <HomeDesktop />;
  }
};

export default Home;

import styled from "styled-components";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import { signOut } from "firebase/auth";
import { fireAuth } from "../../../Firebase/firebase";
import NavbarMobileButton from "../NavbarMobileButton";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
} from "../../../styleConstants";

// this overall wrapper has no margin to block out all content that exists outside margin, like sign in box.
const WhiteWrapper = styled.div`
  position: fixed;
  top: 0;
  background: white;
  touch-action: pinch-zoom;

  height: 100%;
  width: 100%;
`;

// here, the contents of the menu sit inside a margin, while the outer wrapper blocks all things in margin.
const Wrapper = styled.div`
  // this is for mobile margin. If we change this margin for the page, change the 32px here.
  width: calc(100% - ${2 * mobileMarginVal}px);

  // desktop margin for screen widths at the cutoff or greater
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    width: calc(100% - ${2 * desktopMarginVal}px);
  }
`;

const MenuItemWrapper = styled.div`
  display: flex;
  height: 62px;
  align-items: center;

  color: black;
  font-weight: 500;

  border-bottom: 1px solid #e5e5e5;
`;

const MenuX = styled.span`
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid #e5e5e5;

  font-size: 26px;
  color: black;
  // color: #3f3f3f;
`;

const SignInWrapper = styled.span`
  margin-top: 44px;
  display: flex;
  justify-content: center;
`;

const MobileMenu = ({ closeMenu }) => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo.value);
  const userSignedIn = userInfo?.uid;

  return (
    <WhiteWrapper>
      <Wrapper>
        <MenuX onClick={closeMenu}>
          <FontAwesomeIcon icon={faXmark} />
        </MenuX>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <MenuItemWrapper onClick={closeMenu}>Home</MenuItemWrapper>
        </NavLink>
        <NavLink
          to="/petitions"
          style={{ textDecoration: "none", color: "black", fontWeight: "500" }}
        >
          <MenuItemWrapper onClick={closeMenu}>Petitions</MenuItemWrapper>
        </NavLink>
        <NavLink
          to="/about"
          style={{ textDecoration: "none", color: "black", fontWeight: "500" }}
        >
          <MenuItemWrapper onClick={closeMenu}>About</MenuItemWrapper>
        </NavLink>
        <SignInWrapper onClick={closeMenu}>
          {userSignedIn ? (
            <NavbarMobileButton
              text={"SIGN OUT"}
              onClick={() => {
                signOut(fireAuth);
              }}
            />
          ) : (
            <NavbarMobileButton
              text={"SIGN IN"}
              onClick={() => {
                navigate("/signin");
              }}
            />
          )}
        </SignInWrapper>
      </Wrapper>
    </WhiteWrapper>
  );
};

MobileMenu.propTypes = {
  closeMenu: PropTypes.func,
};

export default MobileMenu;

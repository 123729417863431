import styled from "styled-components";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { setFreezeAppScrolling } from "../../Redux/freezeAppScrollingSlice";
import LoadingGuts from "./LoadingGuts";
import NoMatchGuts from "./NoMatchGuts/NoMatchGuts";
import MultiMatchGuts from "./MultiMatchGuts";

const Wrapper = styled.div`
  overflow-y: auto;
  position: fixed;
  width: 100%;
  height: 100vh; // for some reason 100vh instead of 100% fixes the bug on mobile pixel browser of when I scroll the header (with the url, tab count, etc.) away, things behind this overlay don't show up at the top or bottom.
  top: 0;
  bottom: 0;
  background: white;
`;

const MatchedVoterOverlay = () => {
  // const dispatch = useDispatch();
  const isVoterSetUpLoading = useSelector((state) => state.userLoading.value);
  const allMatchedVoters = useSelector((state) => state.matchedAllVoters.value);
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  const userInfo = useSelector((state) => state.userInfo.value);

  // JUST FOR TESTING RIGHT NOW
  // const allMatchedVoters = [];
  // const selectedVoter = null;

  let overlayGuts = <></>;
  if (isVoterSetUpLoading) {
    overlayGuts = <LoadingGuts />;
  } else if (userInfo?.uid && !selectedVoter) {
    if (allMatchedVoters && allMatchedVoters.length > 0) {
      overlayGuts = <MultiMatchGuts />;
    } else if (allMatchedVoters && allMatchedVoters.length < 1) {
      overlayGuts = <NoMatchGuts />;
    }
  }

  return <Wrapper>{overlayGuts}</Wrapper>;
  // if (overlayGuts) {
  //   // dispatch(setFreezeAppScrolling(true));
  //   return <Wrapper>{overlayGuts}</Wrapper>;
  // } else {
  //   // dispatch(setFreezeAppScrolling(false));
  //   return <></>;
  // }
};

export default MatchedVoterOverlay;

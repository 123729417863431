import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  getCheckVoterScenario,
  isCaZip,
  scenario_enums,
} from "../ComponentsAndUtils/utils";
import SlideIn from "../ComponentsAndUtils/SlideIn";
import UpdateNoContact from "./UpdateNoContact";
import UpdateInactive from "./UpdateInactive";
import UpdateNotFound from "./UpdateNotFound";
import UpdateHasContact from "./UpdateHasContact";
import SignInNavBar from "../ComponentsAndUtils/SignInNavBar";
import ContactFooter from "../ComponentsAndUtils/ContactFooter";
import { mobileMarginVal } from "../../../styleConstants";

const ResultsWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: ${(props) =>
    props.$footerHeight}px; // this is the height of the contact footer including border.

  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const SignInHelpVoterUpdate = () => {
  const location = useLocation();
  const [slideInOpen, setSlideInOpen] = useState(null);
  const [footerHeight, setFooterHeight] = useState(null);

  const navigate = useNavigate();
  const checkVoterInfoSubmitted = useSelector(
    (state) => state.checkVoterInfoSubmitted.value,
  );
  const zip = checkVoterInfoSubmitted?.zip;
  const isCa = isCaZip(zip);
  const checkVoterResults = useSelector(
    (state) => state.checkVoterResults.value,
  );

  useEffect(() => {
    // If we've come to the help page from anywhere other than voter-info or update-instructions, just redirect to /signin home.
    // This is to ensure user is walking through path as intended.
    if (
      location?.state?.from != "/signin/help/voter-info" &&
      location?.state?.from != "/signin/help/update-instructions"
    ) {
      navigate("/signin", { replace: true });
    }
  }, []);

  const cvScenario = getCheckVoterScenario(checkVoterResults);
  let updateGuts = <></>;
  if (cvScenario === scenario_enums.ACTIVE_HAS_CONTACT) {
    updateGuts = <UpdateHasContact setSlideInOpen={setSlideInOpen} />;
  } else if (cvScenario === scenario_enums.ACTIVE_NO_CONTACT) {
    updateGuts = <UpdateNoContact setSlideInOpen={setSlideInOpen} />;
  } else if (cvScenario === scenario_enums.INACTIVE) {
    updateGuts = <UpdateInactive setSlideInOpen={setSlideInOpen} />;
  } else if (cvScenario === scenario_enums.NOT_FOUND) {
    updateGuts = <UpdateNotFound setSlideInOpen={setSlideInOpen} />;
  } else if (cvScenario === scenario_enums.INVALID_STATE) {
    navigate("/signin/help/voter-info", { replace: true });
  } else if (cvScenario === scenario_enums.ERROR) {
    navigate("/signin/help/voter-info", { replace: true });
  }

  const isNewRegistration = cvScenario === scenario_enums.NOT_FOUND;

  let privacySlideInGuts = <></>;
  if (isCa) {
    privacySlideInGuts = (
      <>
        When you register to vote in California, some of your voter information
        is made available to political candidates, campaigns, or for scholarly
        or journalistic purposes. This includes the phone number and/or email
        address you register to vote with. You can read more about this{" "}
        <NavLink
          target="_blank"
          to={
            "https://sos.ca.gov/elections/voter-registration/voter-registration-information-file-request"
          }
          style={{ color: "black" }}
        >
          here
        </NavLink>
        .<br />
        <br />
        This means that you may get political texts or emails at the phone
        number or email address you register to vote with, even though including
        a phone number or email address in your voter registration is required
        to sign in to Verified Voice as a verified voter.
        <br />
        <br /> If you want to be able to sign in to Verified Voice but are
        concerned about receiving unwanted political messages, you can create a
        new email address to register to vote with. Political messages would be
        sent there, and you could still use it to sign in to Verified Voice.
      </>
    );
  } else {
    privacySlideInGuts = (
      <>
        When you register to vote in Florida, some of your voter information is
        made available as public information. This includes the phone number
        and/or email address you register to vote with. You can read more about
        this{" "}
        <NavLink
          target="_blank"
          to={
            "https://dos.fl.gov/elections/for-voters/voter-registration/voter-information-as-a-public-record/"
          }
          style={{ color: "black" }}
        >
          here
        </NavLink>
        .<br />
        <br />
        If you are concerned about making your phone number or email address
        public by including it in your voter registration, you can create a new
        email address specifically to register to vote with. This new email
        address would be made public once included in your voter registration
        instead of your existing personal contact. <br />
        <br />
        In either case, you must include some SMS-capable phone number and/or
        email address in your voter registration to be used to sign in to
        Verified Voice.
      </>
    );
  }

  const notificationSlideInGuts = (
    <>
      {isNewRegistration ? (
        <>
          It may take {isCa ? "2-3" : "1-2"} months for your new voter
          registration to appear in our system.
        </>
      ) : (
        <>
          It may take {isCa ? "2-3" : "1-2"} months for your updated voter
          registration to be reflected in our system.
        </>
      )}
      <br />
      <br />
      <Bold>Text (650) 646-4333 or email info@verifiedvoice.org</Bold> from the
      phone or email {`you've`} added to your voter registration to be notified
      once {`it's`} in our system.
      <br />
      <br />
      We will contact you when {`you're`} able to sign in. Until then, feel free
      to browse Verified Voice or reach out with any questions.
    </>
  );

  let vlUpdateSlideInGuts = <></>;
  if (isCa) {
    vlUpdateSlideInGuts = (
      <>
        The state of California typically processes{" "}
        {isNewRegistration
          ? "new online voter registrations"
          : "voter registrations updated online"}{" "}
        within a few days. However, Verified Voice does not have immediate
        access to the most up-to-date California voter list.
        <br />
        <br />
        As an organization, we request a copy of the voter list from the state
        of California each time we want a new version. This involves a written
        application, time for the state to process our request, and finally, the
        delivery of the voter data. You can read more about this{" "}
        <NavLink
          target="_blank"
          to={
            "https://www.sos.ca.gov/elections/voter-registration/voter-registration-information-file-request"
          }
          style={{ color: "black" }}
        >
          here
        </NavLink>
        .
        <br />
        <br />
        Because of this process, it can take 2-3 months for{" "}
        {isNewRegistration
          ? "new voter registrations to appear"
          : "updates to voter registrations to be reflected"}{" "}
        in our system. We do our best to make this happen as soon as possible.
      </>
    );
  } else {
    vlUpdateSlideInGuts = (
      <>
        Verified Voice receives updated voter lists once a month from the state
        of Florida, following{" "}
        <NavLink
          target="_blank"
          to={
            "https://dos.fl.gov/elections/data-statistics/voter-registration-statistics/voter-extract-request/"
          }
          style={{ color: "black" }}
        >
          this process and schedule
        </NavLink>
        . Each new voter list is included in our system as it is received.
        <br />
        <br />
        {isNewRegistration ? (
          <>
            An updated voter list for a given month will only include new voter
            registrations made in the month prior or before. This means that new
            voters typically appear in our system towards the end of the month
            following the month in which they registered.
          </>
        ) : (
          <>
            An updated voter list for a given month will only include voter
            updates made in the month prior or before. This means that voter
            updates typically appear in our system towards the end of the month
            following the month they are made.
          </>
        )}
      </>
    );
  }

  let slideInContents = <></>;
  if (slideInOpen === "voterPrivacy") {
    slideInContents = privacySlideInGuts;
  } else if (slideInOpen === "setNotification") {
    slideInContents = notificationSlideInGuts;
  } else if (slideInOpen === "voterListUpdate") {
    slideInContents = vlUpdateSlideInGuts;
  }

  return (
    <>
      {slideInOpen && (
        <SlideIn
          close={() => {
            setSlideInOpen(null);
          }}
          contents={slideInContents}
        />
      )}
      <SignInNavBar
        includeVoterInfo={true}
        backPath={
          location?.state?.from === "/signin/help/update-instructions"
            ? "/signin/help/update-instructions"
            : "/signin/help/voter-info"
        }
        slideInOpen={slideInOpen && true}
      />
      <ResultsWrapper $footerHeight={footerHeight} $stopScroll={slideInOpen}>
        {updateGuts}
      </ResultsWrapper>
      <ContactFooter setFooterHeight={setFooterHeight} />
    </>
  );
};

SignInHelpVoterUpdate.propTypes = {
  goBack: PropTypes.func,
  openSignInConditions: PropTypes.func,
};

export default SignInHelpVoterUpdate;

import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const StickyTop = styled.div`
  position: fixed; // on localhost, making this position sticky causes a scroll bug on mobile where bottom and top don't stick on scroll.
  top: 0;
  background: white;
  padding-top: 177px; // 163px for total header, 14px padding top.
  border-bottom: 1px solid #d6d6d6;
  width: calc(100% - 32px);
  @media screen and (min-width: 540px) {
    width: 451px;
    padding-top: 198px; // more gap in desktop view
  }
`;

const Title = styled.div`
  font-weight: 500;
  cursor: pointer;
  padding-bottom: 1px;

  @media screen and (min-width: 540px) {
    font-size: 18px;
    padding-bottom: 2px;
  }
`;

const CaretWrapper = styled.span`
  padding-right: 18px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  padding-top: 4px;
  line-height: 24px;

  @media screen and (min-width: 540px) {
    justify-content: flex-start;
    font-size: 18px;
    line-height: 30px;
  }
`;

const StickyBottom = styled.div`
  position: sticky;

  bottom: 0;
  background: white;
  border-top: 1px solid #d6d6d6;

  width: calc(100%);

  display: flex;
  justify-content: space-between;

  font-weight: 300;
  line-height: 38px;

  @media screen and (min-width: 540px) {
    width: 451px;
    font-size: 18px;
    justify-content: flex-start;
    line-height: 40px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between; // this sort of spreads everything out. Expanding the last column makes this look better.
  line-height: 22px;
  font-weight: 300;
  border-bottom: ${(props) =>
    props.$isLastRow
      ? ""
      : "1px solid rgba(0, 0, 0, 0.07);"}; // 1px solid rgba(0, 0, 0, 0.07); // #d6d6d6;

  @media screen and (min-width: 540px) {
    justify-content: flex-start;
    width: 451px;
  }
`;

const DistrictCell = styled.div`
  width: 60px;
  @media screen and (min-width: 540px) {
    width: 94px;
  }
`;

const YesNoCell = styled.div`
  width: 62px;
  padding-left: 4px;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  @media screen and (min-width: 540px) {
    width: 114px;
  }
`;

const TotalVotersCell = styled.div`
  width: calc(
    (0.2 * 100vw) + 26px
  ); // kind of hacky thing, but allows width to to be 90px when screen width is 320, and grows as screen gets wider.
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  padding-left: 4px;
  @media screen and (min-width: 540px) {
    width: 114px;
  }
`;

const DistrictsWrapper = styled.div`
  margin-top: 69px; // this accounts for the fixed header on top

  @media screen and (min-width: 540px) {
    margin-top: 85px;
  }
`;

const Expanded = ({ state, data, collapse }) => {
  const districtRows = [];
  const stateDistrictCount = state === "ca" ? 52 : 28;
  for (let i = 1; i <= stateDistrictCount; i++) {
    districtRows.push(
      <Row key={i} $isLastRow={i === stateDistrictCount}>
        <DistrictCell>{i}</DistrictCell>
        <YesNoCell>{data[i]["YES"].toLocaleString()}</YesNoCell>
        <YesNoCell>{data[i]["NO"].toLocaleString()}</YesNoCell>
        <TotalVotersCell>{data[i]["TOTAL"].toLocaleString()}</TotalVotersCell>
      </Row>,
    );
  }

  return (
    <>
      <StickyTop>
        <Title onClick={() => collapse()}>
          <CaretWrapper>
            <FontAwesomeIcon icon={faCaretDown} />
          </CaretWrapper>
          {state === "ca" ? "California" : "Florida"}
        </Title>
        <Header>
          <DistrictCell>District</DistrictCell>
          <YesNoCell>Yes</YesNoCell>
          <YesNoCell>No</YesNoCell>
          <TotalVotersCell>Total Voters</TotalVotersCell>
        </Header>
      </StickyTop>
      <DistrictsWrapper>{districtRows}</DistrictsWrapper>
      <StickyBottom>
        <DistrictCell>Total</DistrictCell>
        <YesNoCell>{data["TOTAL"]["YES"].toLocaleString()}</YesNoCell>
        <YesNoCell>{data["TOTAL"]["NO"].toLocaleString()}</YesNoCell>
        <TotalVotersCell>
          {data["TOTAL"]["TOTAL"].toLocaleString()}
        </TotalVotersCell>
      </StickyBottom>
    </>
  );
};

Expanded.propTypes = {
  state: PropTypes.string,
  data: PropTypes.object,
  collapse: PropTypes.func,
};

export default Expanded;

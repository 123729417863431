import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  border-radius: 6px;
  border: 1px solid black;
  text-align: center;
  color: white;
  background: black;
  font-size: 14px;
  letter-spacing: 0.05em;
  font-weight: 500;

  height: 32px;
  line-height: 32px;
  width: calc(100% - 2px);
  max-width: 480px;

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: pointer;
  &:hover {
    background: white;
    color: black;
    border: 1px solid black;
  }
`;

const NavbarMobileButton = ({ text, onClick }) => {
  return <Wrapper onClick={() => onClick()}>{text}</Wrapper>;
};

NavbarMobileButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavbarMobileButton;

export const isCaZip = (zip) => {
  const zipInt = parseInt(zip);
  const isCaZip = zipInt >= 90000 && zipInt <= 96699;
  return isCaZip;
};

export const isFlZip = (zip) => {
  const zipInt = parseInt(zip);
  const isFlZip = zipInt >= 32000 && zipInt <= 34999;
  return isFlZip;
};

export const notCaOrFlZip = (zip) => {
  return !isCaZip(zip) && !isFlZip(zip);
};

export const scenario_enums = {
  INVALID_STATE: "INVALID_STATE",
  ACTIVE_HAS_CONTACT: "ACTIVE_HAS_CONTACT",
  ACTIVE_NO_CONTACT: "ACTIVE_NO_CONACT",
  INACTIVE: "INACTIVE",
  NOT_FOUND: "NOT_FOUND",
  ERROR: "ERROR",
};

export const getCheckVoterScenario = (fetchedCvResults) => {
  // if the state is not CA of FL, return that scenario
  if (
    fetchedCvResults === scenario_enums.INVALID_STATE ||
    fetchedCvResults === "invalid state"
  ) {
    return scenario_enums.INVALID_STATE;
  }

  // if there is no fetched data or numActive or numInactive voters, return error because we did not fetch correctly.
  const numActiveVoters = fetchedCvResults?.data?.A?.numVotersFound;
  const numInactiveVoters = fetchedCvResults?.data?.I?.numVotersFound;
  if (
    !Number.isInteger(numActiveVoters) ||
    !Number.isInteger(numInactiveVoters)
  ) {
    return scenario_enums.ERROR;
  }

  // return "not found" or "inactive" if there are no active voters
  if (numActiveVoters <= 0) {
    if (numInactiveVoters <= 0) {
      return scenario_enums.NOT_FOUND;
    } else {
      return scenario_enums.INACTIVE;
    }
  }

  // if active voter, determine whether has contact or not.
  if (numActiveVoters > 0) {
    const phones = fetchedCvResults?.data?.A?.phones || [];
    const emails = fetchedCvResults?.data?.A?.emails || [];
    if (phones.length > 0 || emails.length > 0) {
      return scenario_enums.ACTIVE_HAS_CONTACT;
    } else {
      return scenario_enums.ACTIVE_NO_CONTACT;
    }
  }

  // return error if I made it through this logic without returning anything.
  return scenario_enums.ERROR;
};

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid black;
  text-align: center;
  background: black;
  color: white;
  border: 1px solid black;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 500;

  height: 30px;
  line-height: 30px;

  opacity: ${(props) => (props.$isEnabled ? "1.0" : "0.25")};

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: ${(props) => (props.$isEnabled ? "pointer" : "not-allowed")};
  &:hover {
    background: ${(props) => (props.$isEnabled ? "white" : "black")};
    color: ${(props) => (props.$isEnabled ? "black" : "white")};
  }
`;

const IconWrapperLeft = styled.span`
  margin-right: 6px;
`;

const IconWrapperRight = styled.span`
  margin-left: 6px;
`;

const ActionButton = ({
  text,
  onClick,
  isEnabled = true,
  faIconLeft,
  faIconRight,
  isSingleAction = true,
  linkPath,
  linkFrom,
}) => {
  const buttonGuts = (
    <Wrapper $isEnabled={isEnabled} $isSingleAction={isSingleAction}>
      {faIconLeft && (
        <IconWrapperLeft>
          <FontAwesomeIcon icon={faIconLeft} />
        </IconWrapperLeft>
      )}
      {text.toUpperCase()}
      {faIconRight && (
        <IconWrapperRight>
          <FontAwesomeIcon icon={faIconRight} />
        </IconWrapperRight>
      )}
    </Wrapper>
  );

  const wrapperStyle = {
    textDecoration: "none",
    // the 244px min width is to handle "UPDATE VOTER REGISTRATION ->" text
    minWidth: isSingleAction ? "244px" : "0px",
    width: isSingleAction ? "80%" : "100%",
    maxWidth: "320px",
  };

  // No need to handle any actions if disabled. Just render the visual of the button.
  if (!isEnabled) {
    return <div style={wrapperStyle}>{buttonGuts}</div>;
  }

  // The button will either go to a link, or do some onClick action.
  if (linkPath) {
    return (
      <NavLink
        to={linkPath}
        // if we are given a linkFrom, pass that forward in state.
        state={linkFrom && { from: linkFrom }}
        style={wrapperStyle}
      >
        {buttonGuts}
      </NavLink>
    );
  } else {
    return (
      <div
        onClick={() => {
          onClick();
        }}
        style={wrapperStyle}
      >
        {buttonGuts}
      </div>
    );
  }
};

ActionButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isEnabled: PropTypes.bool,
  faIconLeft: PropTypes.any,
  faIconRight: PropTypes.any,
  isSingleAction: PropTypes.bool,
  linkPath: PropTypes.string,
  linkFrom: PropTypes.string,
};

export default ActionButton;

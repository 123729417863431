import styled from "styled-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCheckVoterFirst,
  setCheckVoterLast,
  setCheckVoterDob,
  setCheckVoterZip,
} from "../../../Redux/checkVoterInfoSlice";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { useNavigate, NavLink, useLocation } from "react-router-dom";

import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { TailSpin } from "react-loader-spinner";
import { httpsCallable } from "firebase/functions";
import { fireFunctions } from "../../../Firebase/firebase";

import { formatDob } from "./formatDob";
import { setCheckVoterResults } from "../../../Redux/checkVoterResultsSlice";
import ActionButton from "../ComponentsAndUtils/ActionButton";
import { scenario_enums } from "../ComponentsAndUtils/utils";
import SignInNavBar from "../ComponentsAndUtils/SignInNavBar";
import ContactFooter from "../ComponentsAndUtils/ContactFooter";
import { setCheckVoterInfoSubmitted } from "../../../Redux/checkVoterInfoSubmittedSlice";

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focuscolor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focuscolor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focuscolor,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focuscolor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focuscolor,
    },
  },
}));

const HideNumberToggleWrapper = styled.div`
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
  // this is all to avoid the up and down arrows while still manatinaing number pad on mobile for numeric input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
`;

const PageContentsWrapper = styled.div`
  // just eye-balled what is a good page width.
  max-width: 680px;

  margin-top: 16px;
  margin-bottom: 18px; // a little extra padding (2px) below the last button to make it look better

  padding-bottom: ${(props) =>
    props.$footerHeight}px; // this is the height of the contact footer including border.
`;

const FullInstructionsWrapper = styled.div`
  margin-bottom: 2px;
`;

const SpaceBetweenFields = styled.div`
  min-width: 8px;
`;

const FieldLine = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

const SubNote = styled.div`
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.$buttonEnabled ? "1.0" : "0.25")};
`;

const CenterLoader = styled.div`
  display: flex;
  justify-content: center;
`;

const Heavier = styled.span`
  font-weight: 600;
`;

const ErrorMessageWrapper = styled.div`
  margin-top: 24px;
`;

const SignInHelp = () => {
  // this can be "loading" or "error". We will display these results on this page itself.
  // pertains to fetch. fetch can be "loading" or result in "error". Any other result will take us to the next page.
  const [localFormStatus, setLocalFormStatus] = useState(null);
  const [footerHeight, setFooterHeight] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If I don't come from sign in and I don't come from edit/clear from the navbar, just redirect to /signin home.
    if (
      location?.state?.from != "/signin" &&
      location?.state?.fromEditOrClear != true
    ) {
      navigate("/signin", { replace: true });
    }
  }, []);

  const dispatch = useDispatch();
  const checkVoterInfo = useSelector((state) => state.checkVoterInfo.value);
  const first = checkVoterInfo.first;
  const last = checkVoterInfo.last;
  const dob = checkVoterInfo.dob;
  const zip = checkVoterInfo.zip;

  const setFirst = (first) => {
    dispatch(setCheckVoterFirst(first));
  };
  const setLast = (last) => {
    dispatch(setCheckVoterLast(last));
  };
  const setDob = (dob) => {
    dispatch(setCheckVoterDob(dob));
  };
  const setZip = (zip) => {
    dispatch(setCheckVoterZip(zip));
  };
  const setFetchedResults = (input) => {
    dispatch(setCheckVoterResults(input));
  };
  const setVoterInfoSubmitted = (input) => {
    dispatch(setCheckVoterInfoSubmitted(input));
  };

  // enable the button only if we've filled out all the fields
  let buttonEnabled = false;
  if (
    first.length > 0 &&
    last.length > 0 &&
    dob.length === 10 &&
    zip.length === 5
  ) {
    buttonEnabled = true;
  }

  return (
    <>
      <SignInNavBar includeVoterInfo={false} backPath={"/signin"} />
      <PageContentsWrapper $footerHeight={footerHeight}>
        <FullInstructionsWrapper>
          Enter your information. {`We'll`} find your voter info on file and
          help you get signed in.
        </FullInstructionsWrapper>
        <br />
        <FieldLine>
          <CssTextField
            InputLabelProps={{
              style: { fontFamily: `"Inter", sans-serif` },
            }}
            InputProps={{
              style: { fontFamily: `"Inter", sans-serif` },
            }}
            focuscolor="black"
            label="First Name"
            variant="outlined"
            type="text"
            name="first"
            size="small"
            value={first}
            onChange={(event) => {
              setFirst(event.target.value.toUpperCase());
            }}
          />
          <SpaceBetweenFields />
          <CssTextField
            InputLabelProps={{
              style: { fontFamily: `"Inter", sans-serif` },
            }}
            InputProps={{
              style: { fontFamily: `"Inter", sans-serif` },
            }}
            focuscolor="black"
            label="Last Name"
            variant="outlined"
            type="text"
            name="last"
            size="small"
            value={last}
            onChange={(event) => {
              setLast(event.target.value.toUpperCase());
            }}
          />
        </FieldLine>
        <FieldLine>
          <CssTextField
            InputLabelProps={{
              style: { fontFamily: `"Inter", sans-serif` },
            }}
            InputProps={{
              style: { fontFamily: `"Inter", sans-serif` },
            }}
            focuscolor="black"
            label="Date of Birth"
            variant="outlined"
            type="text"
            name="dob"
            size="small"
            value={dob}
            onChange={(event) => {
              const newVal = event.target.value;
              // in most cases, we want to process the new value in the text field
              let toProcess = newVal;
              // in the case that the new val is identical to the current val with just the last / backspaced, we want to delete the actual numerical char
              if (`${newVal}/` === dob) {
                // removing the last numerical char
                toProcess = newVal.slice(0, newVal.length - 1);
              }
              // process the dob to have the right slashes and day/month/years that make sense.
              setDob(formatDob(toProcess));
            }}
          />
          <SpaceBetweenFields />
          <HideNumberToggleWrapper>
            <CssTextField
              InputLabelProps={{
                style: { fontFamily: `"Inter", sans-serif` },
              }}
              InputProps={{
                style: { fontFamily: `"Inter", sans-serif` },
              }}
              focuscolor="black"
              label="Zip Code"
              variant="outlined"
              type="number"
              name="zip"
              size="small"
              value={zip}
              onChange={(event) => {
                const numsOnly = event.target.value.replace(/[^0-9]/g, "");
                setZip(numsOnly.slice(0, 5));
              }}
            />
          </HideNumberToggleWrapper>
        </FieldLine>
        <SubNote>
          Check spelling. Must match your voter info –{" "}
          <Heavier>
            check here:{" "}
            <NavLink
              target="_blank"
              to="https://registertovote.ca.gov/"
              style={{ color: "rgba(0, 0, 0, 0.64)" }}
            >
              CA
            </NavLink>
            ,{" "}
            <NavLink
              target="_blank"
              to="https://registertovoteflorida.gov/home"
              style={{ color: "rgba(0, 0, 0, 0.64)" }}
            >
              FL
            </NavLink>
            .
          </Heavier>{" "}
          Omit suffix/title from name. Enter birthdate as MM/DD/YYYY.
        </SubNote>
        <ButtonWrapper $buttonEnabled={buttonEnabled}>
          {localFormStatus === "loading" ? (
            <CenterLoader>
              <TailSpin color="black" height={28} width={28} />
            </CenterLoader>
          ) : (
            <ActionButton
              isEnabled={buttonEnabled}
              text={"FIND VOTER INFO"}
              faIconLeft={faMagnifyingGlass}
              onClick={() => {
                if (buttonEnabled) {
                  // Before searching voter, trim whitespace and use the trimmed strings for voter search.
                  const firstTr = first.trim();
                  const lastTr = last.trim();
                  const zipTr = zip.trim();
                  const dobTr = dob.trim();
                  setFirst(firstTr);
                  setLast(lastTr);
                  setZip(zipTr);
                  setDob(dobTr);

                  // check zip validity before searching voter.
                  const zipInt = parseInt(zipTr);
                  const isCaZip = zipInt >= 90000 && zipInt <= 96699;
                  const isFlZip = zipInt >= 32000 && zipInt <= 34999;
                  if (!isCaZip && !isFlZip) {
                    setFetchedResults(scenario_enums.INVALID_STATE);
                    setVoterInfoSubmitted({
                      first: firstTr,
                      last: lastTr,
                      zip: zipTr,
                      dob: dobTr,
                    });
                    navigate("/signin/help/voter-info", {
                      state: { from: location.pathname },
                    });
                  } else {
                    const getVoterContact = httpsCallable(
                      fireFunctions,
                      "getVoterContact",
                    );
                    setLocalFormStatus("loading");
                    getVoterContact({
                      first: firstTr,
                      last: lastTr,
                      zip: zipTr,
                      dob: dobTr,
                    })
                      .then((resp) => {
                        setVoterInfoSubmitted({
                          first: firstTr,
                          last: lastTr,
                          zip: zipTr,
                          dob: dobTr,
                        });
                        setFetchedResults(resp);
                        navigate("/signin/help/voter-info", {
                          state: { from: location.pathname },
                        });
                      })
                      .catch(() => {
                        setLocalFormStatus("error");
                      });
                  }
                }
              }}
            />
          )}
        </ButtonWrapper>
        {localFormStatus === "error" && (
          <ErrorMessageWrapper>
            Some error occurred. Please try again.
          </ErrorMessageWrapper>
        )}
      </PageContentsWrapper>
      <ContactFooter setFooterHeight={setFooterHeight} />
    </>
  );
};

SignInHelp.propTypes = {
  goBack: PropTypes.func,
  openSignInConditions: PropTypes.func,
};

export default SignInHelp;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: { first: "", last: "", dob: "", zip: "" },
};

export const checkVoterInfoSlice = createSlice({
  name: "checkVoterInfo",
  initialState,
  reducers: {
    setCheckVoterFirst: (state, action) => {
      state.value.first = action.payload;
    },
    setCheckVoterLast: (state, action) => {
      state.value.last = action.payload;
    },
    setCheckVoterDob: (state, action) => {
      state.value.dob = action.payload;
    },
    setCheckVoterZip: (state, action) => {
      state.value.zip = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCheckVoterFirst,
  setCheckVoterLast,
  setCheckVoterDob,
  setCheckVoterZip,
} = checkVoterInfoSlice.actions;

export default checkVoterInfoSlice.reducer;

// this file exports fireDb, fireAuth, and fireFunctions, which are all used by this frontend to access firestore, firebase auth, and google cloud functions
// follows these instructions: https://firebase.google.com/docs/web/setup

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBDDmHEojxWeL4kbTJ8WM-Yv0V5m8tz1nA",
  authDomain: "oak-rahul.firebaseapp.com",
  databaseURL: "https://oak-rahul.firebaseio.com",
  projectId: "oak-rahul",
  storageBucket: "oak-rahul.appspot.com",
  messagingSenderId: "391686711330",
  appId: "1:391686711330:web:dc5198aeef6f9ff2aa027b",
  measurementId: "G-0DWXTPDSL2",
};

// Initialize Firebase. These are what I use to access firebase throughout the frontend
export const firebaseApp = initializeApp(firebaseConfig);
export const fireDb = getFirestore(firebaseApp);
export const fireAuth = getAuth(firebaseApp);
export const fireFunctions = getFunctions(firebaseApp);

import { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MobileMenu from "./MobileMenu";

const MenuBars = styled.span`
  padding-top: 1px;
  font-size: 22px;
  color: black;
  cursor: pointer;
`;

const NavigationMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <MenuBars
        onClick={() => {
          setMenuOpen(true);
        }}
      >
        <FontAwesomeIcon icon={faBars} />
      </MenuBars>
      {/* only open menu if hamburger is clicked. */}
      {menuOpen && (
        <MobileMenu
          closeMenu={() => {
            setMenuOpen(false);
          }}
        />
      )}
    </>
  );
};

export default NavigationMobile;

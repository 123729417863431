import { useState } from "react";
// import { useDispatch } from "react-redux";
// import { setUserLoading } from "../../Redux/userLoadingSlice";
import PropTypes from "prop-types";
import styled from "styled-components";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { fireAuth } from "../../../../Firebase/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import FormButton from "./SignInBoxButton";

const FieldWrapper = styled.div`
  max-width: 432px;

  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
  // this is all to avoid the up and down arrows while still manatinaing number pad on mobile for numeric input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
`;

const InstructionsLabel = styled.div`
  padding-bottom: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
`;

const SmsNotice = styled.div`
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focuscolor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focuscolor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focuscolor,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focuscolor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focuscolor,
    },
  },
}));

const SignInBoxPhone = ({ onCancel }) => {
  const [formToShow, setFormToShow] = useState("phone");
  const [userPhone, setUserPhone] = useState("");

  // this signifies if we don't have enough digits (it is calculated on the frontend before submit)
  const [errorWithPhone, setErrorWithPhone] = useState(false);
  const [otp, setOtp] = useState("");

  // this signifies that we hit the verify button with a phone number but some error occurred from firebase. We will show that error.
  const [errorVerifySubmit, setErrorVerifySubmit] = useState("");
  // this is the error after we submit otp. most often will be wrong code, but can be other firebase error.
  const [errorWithOtp, setErrorWithOtp] = useState("");

  const generateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        fireAuth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
      );
    }
  };

  const requestOtp = () => {
    generateRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    const rawNumber = `+1${userPhone.replace(/\D/g, "")}`;
    setFormToShow("otp");
    signInWithPhoneNumber(fireAuth, rawNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        setErrorVerifySubmit(error.message);
        setFormToShow("phone");
      });
  };

  const verifyOtp = () => {
    const confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then(() => {
        // auth successful.
      })
      .catch((error) => {
        if (error.code === "auth/invalid-verification-code") {
          setErrorWithOtp("Wrong code. Try again.");
        } else {
          setErrorWithOtp(error.message);
        }
      });
  };

  const phoneInputGuts = (
    <>
      <InstructionsLabel>Enter your phone number:</InstructionsLabel>
      <FieldWrapper>
        <CssTextField
          sx={{
            width: "100%",
            height: "72px",
          }}
          InputLabelProps={{
            style: { fontFamily: `"Inter", sans-serif` },
          }}
          InputProps={{
            style: { fontFamily: `"Inter", sans-serif` },
            startAdornment: (
              <InputAdornment position="start">+1</InputAdornment>
            ),
          }}
          focuscolor="black"
          error={errorWithPhone || errorVerifySubmit !== ""}
          helperText={
            (errorWithPhone && "Enter a complete phone number.") ||
            errorVerifySubmit
          }
          label="Phone"
          variant="standard"
          type="tel"
          name="phoneNumber"
          value={userPhone}
          onChange={(event) => {
            const newVal = event.target.value;
            // strip all non-numeric characters
            let nvFormatted = newVal.replace(/\D/g, "");
            if (nvFormatted[0] === "0" || nvFormatted[0] === "1") {
              nvFormatted = nvFormatted.slice(1);
            }
            if (nvFormatted.length > 0) {
              nvFormatted = `(${nvFormatted}`;
            }
            if (nvFormatted.length > 4) {
              nvFormatted = `${nvFormatted.slice(0, 4)}) ${nvFormatted.slice(
                4,
              )}`;
            }
            if (nvFormatted.length > 9) {
              nvFormatted = `${nvFormatted.slice(0, 9)}-${nvFormatted.slice(
                9,
              )}`;
            }
            if (nvFormatted.length > 14) {
              nvFormatted = nvFormatted.slice(0, 14);
            }
            setUserPhone(nvFormatted);
            setErrorWithPhone(false);
          }}
        />
        <ButtonWrapper>
          <FormButton
            isPrimary={false}
            text={"CANCEL"}
            onClick={() => {
              onCancel();
            }}
          />
          <FormButton
            isPrimary={true}
            text={"VERIFY"}
            onClick={() => {
              if (userPhone.length < 14) {
                setErrorWithPhone(true);
              } else {
                requestOtp();
              }
            }}
          />
        </ButtonWrapper>
      </FieldWrapper>
      <SmsNotice>
        By tapping verify, an SMS may be sent. Message & data rates may apply.
      </SmsNotice>
    </>
  );

  const otpInputGuts = (
    <>
      <InstructionsLabel>
        Enter the 6-digit code we sent to <NoWrap>{userPhone}:</NoWrap>
      </InstructionsLabel>
      <FieldWrapper>
        <CssTextField
          sx={{
            width: "100%",
            height: "72px",
          }}
          InputLabelProps={{
            style: { fontFamily: `"Inter", sans-serif` },
          }}
          InputProps={{
            style: { fontFamily: `"Inter", sans-serif` },
          }}
          focuscolor="black"
          error={errorWithOtp !== ""}
          helperText={errorWithOtp}
          label="6-digit code"
          variant="standard"
          type="number"
          name="phoneConfirmationCode"
          value={otp}
          onChange={(event) => {
            const newVal = event.target.value;
            // strip all non-numeric characters
            let nvFormatted = newVal.replace(/\D/g, "");
            setOtp(nvFormatted);
            setErrorWithOtp("");
          }}
        />
        <ButtonWrapper>
          <FormButton
            isPrimary={false}
            text={"CANCEL"}
            onClick={() => {
              onCancel();
            }}
          />
          <FormButton
            isPrimary={true}
            text={"CONTINUE"}
            onClick={() => {
              verifyOtp();
            }}
          />
        </ButtonWrapper>
      </FieldWrapper>
    </>
  );

  let gutsToDisplay = phoneInputGuts;
  if (formToShow === "otp") {
    gutsToDisplay = otpInputGuts;
  }

  return (
    <>
      {gutsToDisplay}
      <div id="recaptcha-container" />
    </>
  );
};

SignInBoxPhone.propTypes = {
  onCancel: PropTypes.func,
};

export default SignInBoxPhone;

import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { isSignInWithEmailLink } from "firebase/auth";
import { fireAuth } from "../../Firebase/firebase";

import ConditionsSlideIn from "../../Components/ConditionsSlideIn";
import SignInBoxEmail from "./ComponentsAndUtils/SignInBox/SignInBoxEmail";
import SignInBoxPhone from "./ComponentsAndUtils/SignInBox/SignInBoxPhone";
import ActionButton from "./ComponentsAndUtils/ActionButton";
import { mobileMarginVal } from "../../styleConstants";

const SubNote = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  @media screen and (min-width: 540px) {
    margin-top: 6px;
    margin-bottom: 14px;
  }
`;

const Wrapper = styled.div`
  padding-top: ${(props) => (props.$useMobileMargins ? "16px" : "44px")};
  padding-bottom: ${(props) => (props.$useMobileMargins ? "24px" : "36px")};
  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  // 700 used here instead of 680 just to keep opening sentence on one line.
  max-width: min(700px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const SignInBoxWrapper = styled.div`
  margin-top: 20px;

  @media screen and (min-width: 540px) {
    margin-top: 22px;
  }
`;

const Bold = styled.span`
  font-weight: 600;
`;

const BoldLight = styled.span`
  font-weight: 500;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 4px;
  display: flex;
  max-width: 432px;
`;

const ButtonSpace = styled.div`
  min-width: 8px;
`;

const VerifyNote = styled.div`
  font-size: 14px;
  font-style: italic;
`;

const NeedHelpSection = styled.div`
  margin-top: 48px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.64);
`;

const SignInHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useSelector((state) => state.screenWidth.value);
  const useMobileMargins = screenWidth < 540;

  // navigate to petitions if we are already signed in with a selected/matched voter.
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  useEffect(() => {
    if (selectedVoter) {
      navigate("/petitions");
    }
  }, [selectedVoter]);

  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);
  const [signInMethod, setSignInMethod] = useState(null);
  const [fromEmailRedirect, setFromEmailRedirect] = useState(false);

  // in the case that we've set the recaptcha verifier but we cancelled the phone auth attempt, refresh the page once to clear window.recaptchaVerifier
  useEffect(() => {
    if (window.recaptchaVerifier) {
      window.location.reload(false);
    }
  }, [signInMethod]);

  // set flag if we came here from an email sign-in link
  useEffect(() => {
    if (isSignInWithEmailLink(fireAuth, window.location.href)) {
      setFromEmailRedirect(true);
    } else {
      setFromEmailRedirect(false);
    }
  }, [window.location.href]);

  // either the phone or email sign in box that we show.
  let boxToShow = <></>;
  if (signInMethod === "phone") {
    boxToShow = (
      <SignInBoxPhone
        onCancel={() => {
          setSignInMethod(null);
        }}
      />
    );
  } else if (signInMethod === "email" || fromEmailRedirect) {
    boxToShow = (
      <SignInBoxEmail
        fromEmailRedirect={fromEmailRedirect}
        onCancel={() => {
          setSignInMethod(null);
        }}
      />
    );
  }

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <Wrapper
        $useMobileMargins={useMobileMargins}
        $stopScroll={signInConditionsOpen}
      >
        Sign in as a registered voter by verifying the phone or email{" "}
        <Bold>you’ve registered to vote with.</Bold>
        <SubNote>
          Only for{" "}
          <LinkButton
            onClick={() => {
              setSignInConditionsOpen(true);
            }}
          >
            these CA & FL voters
          </LinkButton>
          .
        </SubNote>
        <SignInBoxWrapper>
          {!signInMethod && !fromEmailRedirect && (
            <>
              <ButtonWrapper>
                <ActionButton
                  isSingleAction={false}
                  text={"VERIFY PHONE*"}
                  onClick={() => {
                    setSignInMethod("phone");
                  }}
                  faIconRight={faArrowRight}
                />
                <ButtonSpace />
                <ActionButton
                  isSingleAction={false}
                  text={"VERIFY EMAIL*"}
                  onClick={() => {
                    setSignInMethod("email");
                  }}
                  faIconRight={faArrowRight}
                />
              </ButtonWrapper>
              <VerifyNote>
                *This <BoldLight>MUST</BoldLight> be the phone/email {`you've`}{" "}
                registered to vote with. Click below if you are unsure.
              </VerifyNote>
            </>
          )}
          {(signInMethod || fromEmailRedirect) && boxToShow}
        </SignInBoxWrapper>
        <NavLink to="/signin/help" state={{ from: location.pathname }}>
          <NeedHelpSection>
            Unsure of voter status, phone/email on file, or otherwise need help
            to sign in. <FontAwesomeIcon icon={faArrowRight} />
          </NeedHelpSection>
        </NavLink>
      </Wrapper>
    </>
  );
};

export default SignInHome;

// import PropTypes from "prop-types";
import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import ConditionsSlideIn, {
  caVlUpdateDate,
  flVlUpdateDate,
} from "../../../Components/ConditionsSlideIn";
import { isCaZip } from "../ComponentsAndUtils/utils";
import ActionButton from "../ComponentsAndUtils/ActionButton";
import { mobileMarginVal } from "../../../styleConstants";

const Wrapper = styled.div`
  // font-weight: 300;
  // color: rgba(0, 0, 0, 0.64);

  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const SubNote = styled.div`
  margin-top: 4px;
  margin-bottom: 2px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const ButtonWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`;

const Bold = styled.span`
  font-weight: 600;
  color: black;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const ResultsActiveNoContact = () => {
  const location = useLocation();

  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);
  const checkVoterInfoSubmitted = useSelector(
    (state) => state.checkVoterInfoSubmitted.value,
  );
  const zip = checkVoterInfoSubmitted.zip;
  const isCa = isCaZip(zip);

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <Wrapper $stopScroll={signInConditionsOpen}>
        You are registered to vote{" "}
        <Bold>without a phone number or email address.</Bold>
        <SubNote>
          (as of {isCa ? caVlUpdateDate : flVlUpdateDate}, our last{" "}
          {isCa ? "California" : "Florida"} voter list update)
        </SubNote>
        <br />
        To sign in to Verified Voice as a verified voter,{" "}
        <Bold>you must update your voter registration</Bold> to include an
        SMS-capable phone number or email address to sign in with.
        <SubNote>
          <LinkButton
            onClick={() => {
              setSignInConditionsOpen(true);
            }}
          >
            See {`what's`} required
          </LinkButton>{" "}
          to sign in.
        </SubNote>
        <br />
        <ButtonWrapper>
          <ActionButton
            text="UPDATE VOTER REGISTRATION"
            faIconRight={faArrowRight}
            linkPath={"/signin/help/voter-update"}
            linkFrom={location.pathname}
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

export default ResultsActiveNoContact;

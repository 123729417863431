import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: underline;
  color: black;
`;

const StyledLinkBold = styled.a`
  text-decoration: underline;
  color: black;
  font-weight: 600;
`;

export const allPetitions = [
  {
    url: "carbon-fee-and-dividend",
    fireDbPath: "carbonFeeAndDividend",
    uiTitle: "Carbon Fee and Dividend", // used for petition tile in petition list as well as the specific petion page, and on the stance submit confirmation modal.
    tileDescription:
      "A carbon fee on fossil fuels, distributed as cash dividends to the American people.",
    fullText: (
      <>
        Here we petition the United States Congress to enact a national Carbon
        Fee and Dividend.
        <br />
        <br />
        This policy would tax the carbon content of fossil fuels at the point of
        extraction or import and distribute the proceeds as dividends to the
        American people.
        <br />
        <br />
        {"Citizen's"} Climate {"Lobby's"}{" "}
        <StyledLinkBold
          href="https://citizensclimatelobby.org/price-on-carbon/"
          target="_blank"
        >
          introductory video
        </StyledLinkBold>{" "}
        and{" "}
        <StyledLink
          href="https://citizensclimatelobby.org/basics-carbon-fee-dividend/"
          target="_blank"
        >
          explainer
        </StyledLink>
        <br />
        <StyledLink
          href="https://en.wikipedia.org/wiki/Carbon_fee_and_dividend"
          target="_blank"
        >
          Wikipedia article
        </StyledLink>
      </>
    ),
  },
  {
    url: "permanent-dst",
    fireDbPath: "permanentDst",
    uiTitle: "Permanent DST", // used for petition tile in petition list as well as the specific petion page, and on the stance submit confirmation modal.
    tileDescription:
      "No more time change. Daylight savings time (later sunsets) made permanent year around.",
    fullText: (
      <>
        Here we petition the United States Congress to stop the twice-a-year
        practice of time change. Instead, daylight savings time (summer time
        with later sunsets) would be made permanent year around.
      </>
    ),
  },
];

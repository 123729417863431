import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import ConditionsSlideIn, {
  caVlUpdateDate,
  flVlUpdateDate,
} from "../../../Components/ConditionsSlideIn";
import { isCaZip } from "../ComponentsAndUtils/utils";
import ActionButton from "../ComponentsAndUtils/ActionButton";
import SlideIn from "../ComponentsAndUtils/SlideIn";
import { NavLink } from "react-router-dom";
import { mobileMarginVal } from "../../../styleConstants";

const Wrapper = styled.div`
  // font-weight: 300;
  // color: rgba(0, 0, 0, 0.64);

  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // just eye-balled what is a good page width.
  // for mobile views, I want to set a max width to the screen width minus mobile margins.
  // This is to keep things within margins even when a slide in is open.
  max-width: min(680px, calc(100vw - ${mobileMarginVal * 2}px));
  // max-width: 70ch;
`;

const SubNote = styled.div`
  margin-top: 4px;
  margin-bottom: 2px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const SubNoteCompact = styled.div`
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const ButtonWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

const Bold = styled.span`
  font-weight: 600;
  color: black;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const ResultsInactive = () => {
  const location = useLocation();
  const [slideInOpen, setSlideInOpen] = useState(null);
  const checkVoterInfoSubmitted = useSelector(
    (state) => state.checkVoterInfoSubmitted.value,
  );
  const zip = checkVoterInfoSubmitted.zip;
  const isCa = isCaZip(zip);

  let inactiveVotersGuts = <></>;
  if (isCa) {
    inactiveVotersGuts = (
      <>
        All California voter registrations have active status by default. A
        voter may be given inactive status if the state believes they may have
        moved or have not voted in 2 federal general elections. You can read
        more about inactive voters and California voter list maintenance{" "}
        <NavLink
          target="_blank"
          to={
            "https://www.sos.ca.gov/elections/voter-registration/nvra/laws-standards/nvra-manual/chap-4"
          }
          style={{ color: "black" }}
        >
          here
        </NavLink>
        .
        <br />
        <br />
        Only active voters can sign in and participate on Verified Voice.
      </>
    );
  } else {
    inactiveVotersGuts = (
      <>
        All Florida voter registrations have active status by default. A voter
        may be given inactive status if the state believes they may have moved
        (typically confirmed via mailed address confirmation). You can read more
        about inactive voters and the Florida voter list{" "}
        <NavLink
          target="_blank"
          to={
            "https://dos.fl.gov/elections/data-statistics/voter-registration-statistics/voter-registration-reports/voter-registration-new-and-removed/"
          }
          style={{ color: "black" }}
        >
          here
        </NavLink>
        .
        <br />
        <br />
        Only active voters can sign in and participate on Verified Voice.
      </>
    );
  }

  return (
    <>
      {slideInOpen === "signInConditions" && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSlideInOpen(null);
          }}
        />
      )}
      {slideInOpen === "inactiveVoters" && (
        <SlideIn
          close={() => {
            setSlideInOpen(null);
          }}
          contents={inactiveVotersGuts}
        />
      )}
      <Wrapper $stopScroll={slideInOpen}>
        Your voter status is <Bold>inactive</Bold>.
        <SubNoteCompact>
          (as of {isCa ? caVlUpdateDate : flVlUpdateDate}, our last{" "}
          {isCa ? "California" : "Florida"} voter list update)
        </SubNoteCompact>
        <SubNoteCompact>
          <LinkButton
            onClick={() => {
              setSlideInOpen("inactiveVoters");
            }}
          >
            about inactive voters
          </LinkButton>
        </SubNoteCompact>
        <br />
        Only <Bold>active voters</Bold> can sign in and participate on Verified
        Voice.
        <SubNote>
          <LinkButton
            onClick={() => {
              setSlideInOpen("signInConditions");
            }}
          >
            See {`what's`} required
          </LinkButton>{" "}
          to sign in.
        </SubNote>
        <br />
        Update your voter status to active by{" "}
        {isCaZip
          ? "re-registering to vote"
          : "updating your voter registration online"}
        . Be sure to include an SMS-capable phone number or email address in
        your voter registration. Once your updated voter registration is
        processed by our system, you will be able to use the included
        phone/email to sign in to Verified Voice.
        <br />
        <br />
        <ButtonWrapper>
          <ActionButton
            text="UPDATE VOTER REGISTRATION"
            faIconRight={faArrowRight}
            linkPath={"/signin/help/voter-update"}
            linkFrom={location.pathname}
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

export default ResultsInactive;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setScreenWidth } from "../Redux/screenWidthSlice";

// this hook will mount a listener that listens for window resize and dispatch the screen width to a react state variable.
// the screen width will be used for mobile vs desktop display logic. The hook can be called in the top level app, so the listener is always there while the app is running.
export const useScreenWidthCapture = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // what to do when window resize detected, namely dispatch the window width to redux
    const handleWindowResize = () =>
      dispatch(setScreenWidth(window.innerWidth));

    // mounting the listener that listens to resize. on resize, "handleWindowResize".
    window.addEventListener("resize", handleWindowResize);

    // the cleanup removed the listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
};

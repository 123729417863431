import styled from "styled-components";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { isCaZip } from "../ComponentsAndUtils/utils";

const Bold = styled.span`
  font-weight: 600;
`;

const SubNote = styled.div`
  margin-top: 4px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const SubNote2 = styled.div`
  margin-top: 4px;
  margin-bottom: 2px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const SubNote3 = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
`;

const LinkButtonSubtle = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
`;

const LinkButtonBig = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const UpdateNoContact = ({ setSlideInOpen }) => {
  const checkVoterInfoSubmitted = useSelector(
    (state) => state.checkVoterInfoSubmitted.value,
  );
  const zip = checkVoterInfoSubmitted.zip;
  const isCa = isCaZip(zip);

  return (
    <>
      Update your voter registration{" "}
      <Bold>
        <NavLink
          target="_blank"
          to={
            isCa
              ? "https://registertovote.ca.gov/"
              : "https://registertovoteflorida.gov/home"
          }
          style={{ color: "black" }}
        >
          here
        </NavLink>
        .
      </Bold>{" "}
      <SubNote>
        This is the official {isCa ? "California" : "Florida"} voter
        registration website. Click{" "}
        {isCa ? `"Register to Vote Now"` : `"Register or Update"`} and fill out
        the entire form{isCa ? " to update your voter registration" : ""}.
      </SubNote>
      <br />
      <Bold>Make sure to include an SMS-capable phone and/or email</Bold>{" "}
      {isCa
        ? `in the "Personal Information" section of page 2`
        : "in your registration form"}
      . This will be the phone/email you will use to sign in to Verified Voice.
      <SubNote2>
        <LinkButton
          onClick={() => {
            setSlideInOpen("voterPrivacy");
          }}
        >
          a note on privacy
        </LinkButton>
      </SubNote2>
      <SubNote3>
        You will only be able to sign in to Verified Voice once our system has
        processed your voter update.
      </SubNote3>
      <br />
      <br />
      Final step:{" "}
      <Bold>
        <LinkButtonBig
          onClick={() => {
            setSlideInOpen("setNotification");
          }}
        >
          Set voter update notification
        </LinkButtonBig>
      </Bold>
      <SubNote>
        Once you do this, you are all set. We will notify you when you are in
        our system and can sign in. This may take {isCa ? "2-3" : "1-2"} months.{" "}
        <LinkButtonSubtle
          onClick={() => {
            setSlideInOpen("voterListUpdate");
          }}
        >
          how we update voter lists
        </LinkButtonSubtle>
      </SubNote>
    </>
  );
};

UpdateNoContact.propTypes = {
  setSlideInOpen: PropTypes.func,
};

export default UpdateNoContact;

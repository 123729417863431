import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { signOut } from "firebase/auth";
import { fireAuth } from "../../Firebase/firebase";
import NavbarMobileButton from "./NavbarMobileButton";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
} from "../../styleConstants";

// this overall wrapper has no margin to block out all content that exists outside margin, like sign in box.
const WhiteWrapper = styled.div`
  position: fixed;
  top: 0;
  background: white;
  touch-action: pinch-zoom;

  height: 100%;
  width: 100%;
`;

// here, the contents of the menu sit inside a margin, while the outer wrapper blocks all things in margin.
const Wrapper = styled.div`
  // this is for mobile margin. If we change this margin for the page, change the 32px here.
  width: calc(100% - ${2 * mobileMarginVal}px);

  // desktop margin for screen widths at the cutoff or greater
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    width: calc(100% - ${2 * desktopMarginVal}px);
  }
`;

const AccountLogo = styled.div`
  background: black;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 18px;

  text-align: center;
  line-height: 36px;
  cursor: pointer;
`;

const HeaderLine = styled.span`
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: flex-end;

  border-bottom: 1px solid #e5e5e5;
`;

const SignOutWrapper = styled.span`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

const MenuContentWrapper = styled.div`
  margin-top: 16px;
  font-size: 14px;
  color: #777777;
`;

const AccountMenuMobile = ({ accountLogo, closeMenu }) => {
  // get the user phone or email to display
  const userInfo = useSelector((state) => state.userInfo.value);
  const userContact = userInfo?.phone || userInfo?.email || "";

  // get the selected voter info.
  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  // we want to display the name, and city.
  const voterName = `${selectedVoter?.first || ""} ${
    selectedVoter?.last || ""
  } ${selectedVoter?.suffix || ""}`;
  const voterState = (selectedVoter?.state || "").toUpperCase();
  const voterCity = `${selectedVoter?.city}, ${voterState} (${voterState}-${selectedVoter?.district})`;

  return (
    <WhiteWrapper onClick={closeMenu}>
      <Wrapper>
        <HeaderLine>
          <AccountLogo>{accountLogo}</AccountLogo>
        </HeaderLine>
        <MenuContentWrapper>
          {userContact}
          <br />
          <br />
          {voterName}
          <br />
          {voterCity}
        </MenuContentWrapper>
        <SignOutWrapper>
          <NavbarMobileButton
            text={"SIGN OUT"}
            onClick={() => {
              signOut(fireAuth);
              closeMenu();
            }}
          />
        </SignOutWrapper>
      </Wrapper>
    </WhiteWrapper>
  );
};

AccountMenuMobile.propTypes = {
  accountLogo: PropTypes.string,
  closeMenu: PropTypes.func,
};

export default AccountMenuMobile;

import styled from "styled-components";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { overlayBackgroundZ, overlayZ } from "../../../../styleConstants";

// This will serve as a case to hold the centered white overlay
const OverlayCase = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  // needs to be in front than navbar and navigation menus
  z-index: ${overlayZ};

  display: flex;
  justify-content: center;
  align-items: center;
`;

// the purpose of this is to be the gray back that extends beyond the bottom to help with janky scroll issues, making sure the content is always covered by gray.
const GrayBackBuffer = styled.div`
  position: fixed;
  width: 100%;
  height: 200%; // go beyond the bottom to handle janky scroll on mobile.
  top: 0;
  left: 0;

  // needs to be in front than navbar and navigation menus
  z-index: ${overlayBackgroundZ};
  background: rgba(95, 95, 95, 0.75);
`;

const WhiteOverlay = styled.div`
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  overflow-y: auto;
  width: calc(min(680px, 100% - 24px));
  height: calc(100% - 48px);

  background: white;
`;

const ContentWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;

  color: rgba(0, 0, 0, 0.58);
`;

const Bold = styled.span`
  font-weight: 600;
  color: black;
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background: white;

  padding-top: 16px;
  padding-bottom: 12px;

  padding-left: 16px;
  padding-right: 16px;

  border-bottom: 1px solid #e5e5e5;
`;

const X = styled.div`
  font-size: 26px;
  color: black;
`;

const ClickableX = styled.span`
  cursor: pointer;
`;

const ConfirmationOverlay = ({ closeModal, petitionName }) => {
  return (
    <>
      <OverlayCase>
        <WhiteOverlay>
          <StickyHeader>
            <X>
              <ClickableX>
                <FontAwesomeIcon icon={faXmark} onClick={closeModal} />
              </ClickableX>
            </X>
          </StickyHeader>
          <ContentWrapper>
            <Bold>Success!</Bold> Your stance is included in the overall opinion
            of this petition, along with all other registered voter opinions
            inputted on Verified Voice.
            <br />
            <br />
            <Bold>Your next steps:</Bold>
            <br />
            <br />
            <Bold>1. Share this petition with others.</Bold> The overall opinion
            gains relevance as it grows. Sharing with even just a few others can
            ripple effect, grow participation, and send a strong message to
            elected officials.
            <br />
            <br />
            <Bold>2. Vote.</Bold> Our elected representatives are the ones who
            enact policy. We should choose the ones that best represent our
            beliefs, including on the issue of {petitionName}.
          </ContentWrapper>
        </WhiteOverlay>
      </OverlayCase>
      <GrayBackBuffer />
    </>
  );
};

ConfirmationOverlay.propTypes = {
  closeModal: PropTypes.func,
  petitionName: PropTypes.string,
};

export default ConfirmationOverlay;

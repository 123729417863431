import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// import { NavLink } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { TailSpin } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { fireFunctions } from "../../../../Firebase/firebase";
import Expanded from "./Expanded";

const caDistrictPop = {
  1: 426635,
  2: 480192,
  3: 526461,
  4: 442302,
  5: 484385,
  6: 407665,
  7: 420267,
  8: 415641,
  9: 357223,
  10: 484049,
  11: 439699,
  12: 435370,
  13: 330142,
  14: 415291,
  15: 413281,
  16: 442833,
  17: 365545,
  18: 354619,
  19: 479087,
  20: 449976,
  21: 343698,
  22: 307237,
  23: 409835,
  24: 442732,
  25: 382162,
  26: 459881,
  27: 449231,
  28: 464604,
  29: 381853,
  30: 474572,
  31: 414297,
  32: 478440,
  33: 401163,
  34: 358925,
  35: 386565,
  36: 498353,
  37: 399982,
  38: 449040,
  39: 379276,
  40: 491664,
  41: 472846,
  42: 400569,
  43: 403847,
  44: 421814,
  45: 443370,
  46: 337012,
  47: 451358,
  48: 477114,
  49: 473480,
  50: 455558,
  51: 435926,
  52: 417775,
};

const flDistrictPop = {
  1: 533193,
  2: 500528,
  3: 460175,
  4: 507409,
  5: 526848,
  6: 517697,
  7: 563523,
  8: 533060,
  9: 432111,
  10: 403216,
  11: 513202,
  12: 523098,
  13: 497669,
  14: 435362,
  15: 426757,
  16: 495405,
  17: 547480,
  18: 438323,
  19: 506938,
  20: 433335,
  21: 550057,
  22: 430946,
  23: 512960,
  24: 419587,
  25: 504225,
  26: 399745,
  27: 423707,
  28: 430339,
};

const LoadingWrapper = styled.div`
  padding-top: 14px;
  display: flex;

  @media screen and (min-width: 540px) {
    padding-top: 22px;
    font-size: 18px;
  }
`;

const TailSpinWrapper = styled.div`
  padding-left: 12px;
`;

// for Summary only below

const SummaryWrapper = styled.div`
  padding-top: 14px;
  padding-bottom: 12px;
  font-weight: 300;

  @media screen and (min-width: 540px) {
    padding-top: 22px;
    font-size: 18px;
  }
`;

const CaretWrapper = styled.span`
  padding-right: 20px;
`;

const Title = styled.div`
  margin-top: 18px;
  font-weight: 500;
  cursor: pointer;

  @media screen and (min-width: 540px) {
    margin-top: 26px;
  }
`;

const TitleNoClick = styled.div`
  margin-top: 18px;
  font-weight: 500;

  @media screen and (min-width: 540px) {
    margin-top: 26px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  line-height: 22px;

  @media screen and (min-width: 540px) {
    justify-content: flex-start;
    line-height: 27px;
  }
`;

const DistrictCell = styled.div`
  width: 60px;
  @media screen and (min-width: 540px) {
    width: 94px;
  }
`;

const YesNoCell = styled.div`
  width: 62px;
  padding-left: 4px;
  border-left: 1px solid rgba(0, 0, 0, 0); // pure white to blend in. We want these cells to match expanded exactly but no border.

  @media screen and (min-width: 540px) {
    width: 114px;
  }
`;

const TotalVotersCell = styled.div`
  width: calc(
    (0.2 * 100vw) + 26px
  ); // kind of hacky thing, but allows width to to be 90px when screen width is 320, and grows as screen gets wider.
  border-left: 1px solid rgba(0, 0, 0, 0); // pure white to blend in. We want these cells to match expanded exactly but no border.
  padding-left: 4px;

  @media screen and (min-width: 540px) {
    width: 114px;
  }
`;

const Bold = styled.span`
  font-weight: 500;
`;

const SubNote = styled.div`
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  @media screen and (min-width: 540px) {
    font-size: 16px;
  }
`;

const OverallOpinion = ({ fireDbPetitionPath }) => {
  const [resultsData, setResultsData] = useState(null);
  const [expandedState, setExpandedState] = useState(null);

  // fetch voterResults
  useEffect(() => {
    const getResultsBackend = httpsCallable(
      fireFunctions,
      "getStancesByDistrict",
    );
    getResultsBackend({ fireDbPetition: fireDbPetitionPath }).then(
      (results) => {
        // calculating totals for each state.
        const data = results.data;
        ["ca", "fl"].forEach((state) => {
          // the total yes's, no's and total voters accross districts
          const stateTotals = { YES: 0, NO: 0, TOTAL: 0 };
          const stateDistrictCount = state === "ca" ? 52 : 28;
          // going through each district
          for (let i = 1; i <= stateDistrictCount; i++) {
            const distrStr = i.toString();
            const districtStances = data[state][distrStr];
            // updating the total yes's and no's by adding this district's stances to the total.
            stateTotals["YES"] += districtStances["YES"];
            stateTotals["NO"] += districtStances["NO"];
            // getting the total voters in the district
            const districtPop = (
              state === "ca" ? caDistrictPop : flDistrictPop
            )[distrStr];
            // adding district pop to total state pop, as well as setting the district pop as a field for this district.
            stateTotals["TOTAL"] += districtPop;
            data[state][distrStr]["TOTAL"] = districtPop;
          }
          data[state]["TOTAL"] = stateTotals;
        });
        setResultsData(data);
      },
    );
  }, []);

  let guts = <></>;
  if (!resultsData) {
    guts = (
      <LoadingWrapper>
        Fetching results...{" "}
        <TailSpinWrapper>
          <TailSpin color="black" height={24} width={24} />
        </TailSpinWrapper>
      </LoadingWrapper>
    );
  } else {
    if (expandedState) {
      guts = (
        <Expanded
          state={expandedState}
          data={resultsData[expandedState]}
          collapse={() => {
            setExpandedState(null);
          }}
        />
      );
    } else {
      const caYes = resultsData["ca"]["TOTAL"]["YES"];
      const caNo = resultsData["ca"]["TOTAL"]["NO"];
      const caTotal = resultsData["ca"]["TOTAL"]["TOTAL"];
      const flYes = resultsData["fl"]["TOTAL"]["YES"];
      const flNo = resultsData["fl"]["TOTAL"]["NO"];
      const flTotal = resultsData["fl"]["TOTAL"]["TOTAL"];
      const usYes = caYes + flYes;
      const usNo = caNo + flNo;
      const usTotal = caTotal + flTotal;

      guts = (
        <SummaryWrapper>
          <Bold>Total support (Yes)/opposition (No)</Bold>
          <SubNote>
            Includes all voter stances entered on Verified Voice for this
            petition.
          </SubNote>
          <TitleNoClick>U.S. Total (CA & FL)</TitleNoClick>
          <Header>
            <DistrictCell></DistrictCell>
            <YesNoCell>Yes</YesNoCell>
            <YesNoCell>No</YesNoCell>
            <TotalVotersCell>Total Voters</TotalVotersCell>
          </Header>
          <Header>
            <DistrictCell></DistrictCell>
            <YesNoCell>{usYes.toLocaleString()}</YesNoCell>
            <YesNoCell>{usNo.toLocaleString()}</YesNoCell>
            <TotalVotersCell>{usTotal.toLocaleString()}</TotalVotersCell>
          </Header>
          <Title
            onClick={() => {
              setExpandedState("ca");
            }}
          >
            <CaretWrapper>
              <FontAwesomeIcon icon={faCaretRight} />
            </CaretWrapper>
            California
          </Title>
          <Header>
            <DistrictCell></DistrictCell>
            <YesNoCell>Yes</YesNoCell>
            <YesNoCell>No</YesNoCell>
            <TotalVotersCell>Total Voters</TotalVotersCell>
          </Header>
          <Header>
            <DistrictCell></DistrictCell>
            <YesNoCell>{caYes.toLocaleString()}</YesNoCell>
            <YesNoCell>{caNo.toLocaleString()}</YesNoCell>
            <TotalVotersCell>{caTotal.toLocaleString()}</TotalVotersCell>
          </Header>
          <Title
            onClick={() => {
              setExpandedState("fl");
            }}
          >
            <CaretWrapper>
              <FontAwesomeIcon icon={faCaretRight} />
            </CaretWrapper>
            Florida
          </Title>
          <Header>
            <DistrictCell></DistrictCell>
            <YesNoCell>Yes</YesNoCell>
            <YesNoCell>No</YesNoCell>
            <TotalVotersCell>Total Voters</TotalVotersCell>
          </Header>
          <Header>
            <DistrictCell></DistrictCell>
            <YesNoCell>{flYes.toLocaleString()}</YesNoCell>
            <YesNoCell>{flNo.toLocaleString()}</YesNoCell>
            <TotalVotersCell>{flTotal.toLocaleString()}</TotalVotersCell>
          </Header>
        </SummaryWrapper>
      );
    }
  }

  return guts;
};

OverallOpinion.propTypes = {
  fireDbPetitionPath: PropTypes.string,
};

export default OverallOpinion;

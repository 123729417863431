import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const checkVoterResultsSlice = createSlice({
  name: "checkVoterResults",
  initialState,
  reducers: {
    setCheckVoterResults: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCheckVoterResults } = checkVoterResultsSlice.actions;

export default checkVoterResultsSlice.reducer;

// helper function for formatting Dob
const isValidDay = (dayInput, month) => {
  const m31days = [1, 3, 5, 7, 8, 10, 12];
  const m30days = [4, 6, 9, 11];
  if (m31days.includes(month)) {
    if (dayInput <= 31) {
      return true;
    }
  } else if (m30days.includes(month)) {
    if (dayInput <= 30) {
      return true;
    }
  } else if (month === 2) {
    if (dayInput <= 29) {
      return true;
    }
  }
  return false;
};

export const formatDob = (input) => {
  // start with just numerical chars
  let dateSoFar = input.replace(/[^0-9]/g, "");
  if (dateSoFar === "") {
    return dateSoFar;
  }

  // check if we need to put a 0 before the first digit. This is true for digits bigger than 1, like 3, where 03 would signify march
  const firstDigit = parseInt(dateSoFar[0]);
  if (firstDigit > 1) {
    dateSoFar = `0${dateSoFar}`;
  }
  if (dateSoFar.length < 2) {
    return dateSoFar;
  }

  // check the month in full (first 2 digits). if the month makes no sense, i.e. 14 b/c it is > 12, add a 0 in the beginning so it makes sense.
  const numericalMonth = parseInt(dateSoFar.slice(0, 2));
  if (numericalMonth > 12) {
    dateSoFar = `0${dateSoFar}`;
  }
  // add the slash after first 2 completed digits
  dateSoFar = `${dateSoFar.slice(0, 2)}/${dateSoFar.slice(
    2,
    dateSoFar.length,
  )}`;
  // return if all I have is first 2 digits and a slash
  if (dateSoFar.length <= 3) {
    return dateSoFar;
  }

  // check if I need to put a 0 before the day
  const ourMonth = parseInt(dateSoFar.slice(0, 2));
  const firstDayDigit = parseInt(dateSoFar[3]);
  // insert 0 before the day if the first digit is more than 3 or more than 2 for feb.
  if (firstDayDigit > 3 || (ourMonth === 2 && firstDayDigit > 2)) {
    dateSoFar = `${dateSoFar.slice(0, 3)}0${dateSoFar.slice(
      3,
      dateSoFar.length,
    )}`;
  }
  // only 1 day digit after first slash
  if (dateSoFar.length < 5) {
    return dateSoFar;
  }

  let numericalDay = parseInt(dateSoFar.slice(3, 5));
  while (dateSoFar.length >= 5 && !isValidDay(numericalDay, ourMonth)) {
    // removing the second digit of the month until it is valid
    dateSoFar = dateSoFar = `${dateSoFar.slice(0, 4)}${dateSoFar.slice(
      5,
      dateSoFar.length,
    )}`;
    // only 1 day digit after first slash
    if (dateSoFar.length < 5) {
      return dateSoFar;
    }
    numericalDay = parseInt(dateSoFar.slice(3, 5));
  }

  // add the slash after first 5 completed digits: mm/dd
  dateSoFar = `${dateSoFar.slice(0, 5)}/${dateSoFar.slice(
    5,
    dateSoFar.length,
  )}`;
  // return if all I have is up to the second slash, return
  if (dateSoFar.length <= 6) {
    return dateSoFar;
  }

  while (dateSoFar[6] !== "1" && dateSoFar[6] !== "2") {
    dateSoFar = dateSoFar = `${dateSoFar.slice(0, 6)}${dateSoFar.slice(
      7,
      dateSoFar.length,
    )}`;
    // if there are no more digits after the second slash, return
    if (dateSoFar.length <= 6) {
      return dateSoFar;
    }
  }
  // return if there is only one digit in the year
  if (dateSoFar.length <= 7) {
    return dateSoFar;
  }

  // keep the first 2 digits of the year before 18 and 20
  while (
    !(
      18 <= parseInt(dateSoFar.slice(6, 8)) &&
      parseInt(dateSoFar.slice(6, 8)) <= 20
    )
  ) {
    dateSoFar = dateSoFar = `${dateSoFar.slice(0, 7)}${dateSoFar.slice(
      8,
      dateSoFar.length,
    )}`;
    // if there is only 1 year digit after slash
    if (dateSoFar.length <= 7) {
      return dateSoFar;
    }
  }
  // return if there are only two digit in the year
  if (dateSoFar.length <= 8) {
    return dateSoFar;
  }

  // keep the first 3 digits of the year between 180 and 202
  // NOTE: will have to change upper bound to 203 or beyond once we leave the 2020s.
  while (
    !(
      180 <= parseInt(dateSoFar.slice(6, 9)) &&
      parseInt(dateSoFar.slice(6, 9)) <= 202
    )
  ) {
    dateSoFar = dateSoFar = `${dateSoFar.slice(0, 8)}${dateSoFar.slice(
      9,
      dateSoFar.length,
    )}`;
    // if there are only 2 year digits after slash
    if (dateSoFar.length <= 8) {
      return dateSoFar;
    }
  }

  return dateSoFar.slice(0, 10);
};
